export function updateCurrentChat( { commit } , chatData ){
    if(chatData && Object.keys(chatData).length !== 0) commit('SET_CURRENT_CHAT', chatData.profile.number);
    else commit('SET_CURRENT_CHAT', null);
    commit('SET_CURRENT_CHAT_USER', chatData);
}

export function pushChat({ rootGetters, getters, commit, dispatch }, newchat){
    const currentChats = getters.chats;
    const currentChatNumber = getters.currentChat;
    const user = rootGetters['auth/user'];

    const existChat = currentChats.find(item => item.id === newchat.id);

    if(!existChat){
        
        currentChats.unshift(newchat);
        commit("SET_CHATS", currentChats);
    }else {
        
        const updatedChat = {
            ...existChat,
            status: newchat.status,
            status_name: newchat.status_name,
            profile: newchat.profile,
            user: newchat.user,
            follow_up: newchat.follow_up,
            short_text: newchat.short_text,
            last_message: newchat.last_message ? newchat.last_message : existChat.last_message,
            last_message_from_client: newchat.last_message_from_client
        };

        //console.log(updatedChat);

     const updatedChats = currentChats.map(item => {
            if(item.id === newchat.id){
                return updatedChat;
            }else {
                return item;
            }
        });
        commit("SET_CHATS", updatedChats);

         // Atualizando Chats Atuais pelo websocket e notificados modificando cor //
         // primeiro verifica se o o chat antigo não e do usuario atual e o chat novo e do usuario atual //

         if(existChat.user && existChat.user.id !== user.id && newchat.user.id === user.id){
            const newChatData = getters.newChatData;

            newChatData[existChat.id] = newchat;
   
           commit('SET_NEW_CHAT_DATA', newChatData);
         }

        if(newchat.status === "E"){
            setTimeout(()=> {
                dispatch('removeClosedChats');
            }, 60 * 1000);
        }
       
        // Veriricando se e o chat atual para atualizar a tela //
       if(currentChatNumber === newchat.profile.number){
        const chatData = {
            id: newchat.id,
            profile: newchat.profile,
            user: newchat.user,
            status: newchat.status,
            follow_up: newchat.follow_up,
            short_text: newchat.short_text,
            last_message: newchat.last_message ? newchat.last_message : existChat.last_message,
            last_message_from_client: newchat.last_message_from_client
        }
        commit('SET_CURRENT_CHAT_USER', chatData);
       }
    }
}

export function removeClosedChats({ getters, commit }){
    // console.log("Executando Timeout Remove Closed");
    const currentChats = getters.chats;
    
    const result = currentChats.findIndex(item => item.status === "E");
     
    if(result !== -1){
    
    // const removed = currentChats.splice(result,1);

    // console.log("Chat Removido", removed);

    commit('SET_CHATS',currentChats);

    }
}

export function cleanHistory({ commit }){
    commit("SET_HISTORY_CHATS", null);
}

export function cleanChatsByDates({ commit }){
    commit("SET_CHATS_BY_DATES", null);
}


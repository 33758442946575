<template>
         <v-list-item
         @click="$emit('scrollToMessage')"
         >
            <v-list-item-content>
                <v-list-item-subtitle class="mb-2">
                         {{formatTitle(data.message.Body.Text)}}
                </v-list-item-subtitle>
                <v-list-item-title class="mb-2">
                         {{formatMessage(data.message.Body.Text)}}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption font-italic blue--text text--darken-2">
                        {{formateDateAndHours(data.date)}}
                </v-list-item-subtitle>
            </v-list-item-content>
    </v-list-item>
</template>
<script>
import formatDate from '../../utils/formateDate';
import formatHours from '../../utils/formatHours';
export default {
    name: "SearchTextMessage",
    props: {
         data: Object
    },
    methods: {
           formatTitle(message){
           if(message){
               const value = message.replace(/\*/g,'"');
               const value2 =  value.match(/"(.*?[^\\])"/);
               if(value2){
                const index = value2[1].indexOf('-');

                return `Atendente - ${value2[1].slice(0,index - 1)}`;
              }
             return value2;
          }
          return message;
       },
       formatMessage(message){
         if(message){
           const index = message.lastIndexOf('*');
           return message.slice(index + 1,message.length);
          }
          return message;
       },
       formateDateAndHours(dateString){
           return `${formatDate(dateString,'/',false)} ${formatHours(dateString)}`
       }
    }
}
</script>
import formatMessageText from '../../../utils/messageformat';

export function checkMessageWithError( messages, messageCheckText){

    const messageWIthError = messages.find(item => {
        return formatMessageText(item.message.Body.Text) === formatMessageText(messageCheckText)
               && item.messageWithError
    });

    return messageWIthError;
}


export function updateMessageWithError(messages, updatedMessage){

    const updatedMessages = messages.map(item => {
        if(formatMessageText(item.message.Body.Text) === formatMessageText(updatedMessage.message.Body.Text)
            && item.messageWithError){
            return updatedMessage;
        }else {
            return item;
        }
    });
    return updatedMessages
}

export default function startEndDate(type, backDaysOrMonths){

    const currentDate = new Date();

    const startDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`

        
    function daysInMonth (month, year) {
        return new Date(year, month, 0).getDate();
    }

    if(type === 'days'){

        let endDate;
    
        if(currentDate.getDate() - backDaysOrMonths > 0){
    
            const endDateDay = currentDate.getDate() - backDaysOrMonths,
            endDateMonth = currentDate.getMonth() + 1,
            endDateYear = currentDate.getFullYear();
            endDate = `${endDateYear}-${endDateMonth}-${endDateDay}`
            
        }else {
            if(currentDate.getMonth() === 0){
                const endDateDay = 31 - (backDaysOrMonths  - currentDate.getDate()),
                endDateMonth = 12,
                endDateYear = currentDate.getFullYear() - 1;
                endDate = `${endDateYear}-${endDateMonth}-${endDateDay}`
            }else {
                const endDateMonth = currentDate.getMonth(),
                endDateYear = currentDate.getFullYear(),
                endDateDay = daysInMonth(endDateMonth,endDateYear) - (backDaysOrMonths - currentDate.getDate());
                endDate = `${endDateYear}-${endDateMonth}-${endDateDay}`
            }
        }
        return { startDate, endDate };

    }else {
        const endDateYear = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1: currentDate.getFullYear(),
        endDateMonth = (currentDate.getMonth() + 1) - backDaysOrMonths;

        const daysBackMonth = daysInMonth(endDateMonth,endDateYear);

        const endDateDay = daysBackMonth >= currentDate.getDate() ? currentDate.getDate() : daysBackMonth;

        const endDate = `${endDateYear}-${endDateMonth}-${endDateDay}`

        return { startDate, endDate };
    }
}
<template>
     <v-container class="pa-0">
        <v-container class="d-flex flex-row justify-space-between">
            <p class='ml-4 mt-2 grey--text text--darken-3'>Perfil</p>
            <v-btn
              icon
              @click="closeRightContent"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
        <v-container class="d-flex justify-center">
        <v-avatar
        size="120"
              >
               <img
                :src="imageSrc"
                alt="user-avatar-profile"
               >
        </v-avatar>
        </v-container>
        <v-container class="d-flex justify-center">
            <p>{{formatPhoneNumber(this.currentChatUser.profile.number)}}</p>
        </v-container>
        <v-container class="py-0">
             <v-text-field
          dense
          v-model="name"
          outlined
          append-icon="mdi-pencil"
          color="#1976D2"
        >
        </v-text-field>
        </v-container>
        <v-container class="py-0">
        <CustomButtonLoad
            title="Salvar alteração"
            :customFunction="saveNewUserProfileName"
        />
        </v-container>
     </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import api from "../../services/api";

import default_user from '../../assets/default_user.jpg';
import CustomButtonLoad from '../shared/CustomButtonLoad.vue';
import formatPhoneNumber from '../../utils/formatPhoneNumber';

export default {
    name: "UserProfile",
    data(){
        return {
            name: this.profileName
      }
    },
    components: {
        CustomButtonLoad
    },
    methods: {
        ...mapActions({
            closeRightContent: 'rightcontent/closeRightContent'
        }),
        formatPhoneNumber: formatPhoneNumber,
        async saveNewUserProfileName(){
            if(this.name !== '' && this.name !== null && this.name.length > 0){
            try {
                await api.post('/renameProfile', {
                   id: this.currentChatUser.profile.id,
                   name: this.name
                });
              }catch (error) {
                 console.log(error);
                 console.log(error.response);
                }
            }
        }
    },
    computed: {
      ...mapGetters({
         currentChatUser: 'chat/currentChatUser',
      }),
      imageSrc(){
               return this.currentChatUser.profile.url ? this.currentChatUser.profile.url : default_user;
       },
    profileName(){
        return this.currentChatUser.profile.name ? this.currentChatUser.profile.name : this.currentChatUser.profile.number
      },
    },
    watch: {
        currentChatUser(){
            this.name = this.profileName;
        },
    },
    mounted() {
        this.name = this.profileName;
    }
}
</script>



<template>
    <v-container fluid class="screen-size-main-container">
      <div id="open-content-button" @click="openLeftChats">
         <v-icon size="40" color="white">
           mdi-chevron-left
         </v-icon>
      </div>
      <v-row class="flex-nowrap">
        <v-col 
          class="pa-0 left-content"
          sm="2"
          md="3"
          lg="3"
          xl="3"
        >
        <v-sheet :height="containerHeight" outlined>
            <LeftContent @closeLeftContent="closeLeftChats" />
        </v-sheet>
        </v-col >
        <v-col
           id="mainContent"
           class="pa-0 hidden"
         >
          <v-sheet :height="containerHeight" outlined>
          <TopMessageNav :users='users' @showSearch='showSearchMessage = !showSearchMessage' />
        <v-container
          class="message center"
          v-if="loadingMessages"
        >
        <v-progress-circular
            :size="60"
            indeterminate
            color="primary"
         ></v-progress-circular>
        </v-container>
          <v-container
            v-else
            class="grey lighten-4 overflow-y-auto message"
            id="scroll-target"
          >
          <div id="scrolled-content">
              <v-row
                v-for="(m, idx) in messagesWithDates"
                :key="idx"
                :justify="justify(m)"
                :id="m.id"
              >
              <component v-bind:is="checkMessageType(m)" :data="m" ></component>
              </v-row>
            </div>
          </v-container>
              <SendMessage  v-if="checkCurrentChat == 1" />
          <v-card
            v-else-if="checkCurrentChat == 2"
            elevation="0"
            height="60"
            color="#ededed"
            class="d-flex flex-wrap justify-center align-center"
          >
              <p class="text-card-title mt-1 mb-0">
                  Em Atendimento com: <b>{{ currentChatUser.user.user_name }}</b>
              </p>
              <v-btn text  @click="transferChat(user)" color="blue">
                 <p class="text-card-title mb-0">Pegar Atendimento</p>
              </v-btn>
          </v-card>
          <v-card
            v-else-if='checkCurrentChat == 3'
            elevation="0"
            height="60"
            color="#ededed"
          >
           <v-card-title  class="justify-center">
                     Chat Encerrado
                <v-btn text  @click="beginChat" color="blue">
                 <p class="text-card-title mb-0">Nova Conversa</p>
              </v-btn>
           </v-card-title>
          </v-card>
          <v-card
            v-else
            elevation="0"
            height="60"
            color="#ededed"
          >
           <v-card-title  class="justify-center align-content-center">
                 <v-btn @click="beginChat" color="blue" text>
                     Atender
                 </v-btn>
           </v-card-title>
          </v-card>
          </v-sheet>
      </v-col>
      <v-col
          id="right-content"
          class="pa-0 hidden-right"
          sm="4"
          md="4"
          lg="3"
          xl="2"
      >
        <v-sheet :height="containerHeight" outlined>
            <SearchMessage v-if='showSearchMessages' @scrollToMessage="scrollToMessage"/>
            <ChatConfigContent :user="user" v-if='showChatConfig'/>
            <UserProfile v-if="showUserProfile"  />
            <ChatsDataInfo v-if="showChatsInfo"/>
            <MessagesData v-if="showMessagesData"/>
        </v-sheet>
      </v-col>
      </v-row>
  </v-container>
</template>
<script>

import LeftContent from '../components/LeftContent';
import TopMessageNav from '../components/TopMessageNav';
import SendMessage from '../components/SendMessage'
import VideoMessage from '../components/messages/VideoMessage';
import TextMessage from '../components/messages/TextMessage.vue';
import FileMessage from '../components/messages/FileMessage.vue';
import ImageMessage from '../components/messages/ImageMessage.vue';
import AudioMessage from '../components/messages/AudioMessage.vue';
import ApiMessage from '../components/messages/ApiMessage.vue';
import ChatsDataInfo from '../components/rightcontent/ChatsDataInfo.vue';
import MessagesData from '../components/rightcontent/MessagesDataInfo.vue';
import ChatConfigContent from '../components/rightcontent/ChatConfigContent';
import SearchMessage from '../components/rightcontent/SearchMessage.vue';
import UserProfile from '../components/rightcontent/UserProfile.vue';

import formatExtension from '../utils/formatExtension';

import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ChatPage",
    components: {
      TopMessageNav,
      SendMessage,
      LeftContent,
      ChatConfigContent,
      VideoMessage,
      TextMessage,
      FileMessage,
      ImageMessage,
      AudioMessage,
      ApiMessage,
      SearchMessage,
      UserProfile,
      ChatsDataInfo,
      MessagesData
    },
    data() {
      return {
         users: [],
         container: null,
         elem:  null,
         scrollTop: 400,
         showRight: false,
         currentMessageDate: null,
         showSearchMessage: false,
         windowWidth: window.innerWidth,
      }
    },
    methods: {
        ...mapActions({
            beginChat: 'chat/beginChat',
            transferChat: 'chat/transferChat',
            setAppBar: 'setAppBar',
            loadChats: 'chat/loadChats',
        }),
    showMessageDate(message){
            const messageDate = new Date(message.date);

            const today = new Date();

            today.setHours(0,0,0,0);
            messageDate.setHours(0,0,0,0);

            //console.log(this.currentMessageDate);

            function formatDate(dataObj){
              const day = dataObj.getDate() , month = dataObj.getMonth(), year = dataObj.getFullYear();
              const dayMonth = day < 10 ? `0${day}` : day;
              const fMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
              return `${dayMonth}/${fMonth}/${year}`;
            }

            if(!this.currentMessageDate){
              this.currentMessageDate = messageDate;

              if(messageDate < today){
                return formatDate(messageDate);
              }else {
                  return "Hoje";
              }
            }else{
                if(this.currentMessageDate < messageDate){

                if(messageDate.toDateString() === today.toDateString()){
                  this.currentMessageDate = messageDate;
                   return "Hoje"
                }

               today.setDate(today.getDate() - 1);

               if(messageDate.toDateString() === today.toDateString()){
                  this.currentMessageDate = messageDate;
                   return "Ontem"
                }
                  this.currentMessageDate = messageDate;
                  return formatDate(messageDate);
                }
                else{
                  this.currentMessageDate = messageDate;
                  return '';
                }
            }
        },
       justify(m){
          if(m.isDateMessage){
                return 'center';
            }else if(m.message.Type === 'chat_begin' || m.message.Type === 'chat_close'){
                return 'center';
            }else if (m.message.Body.Info.FromMe){
                return 'end';
            }else {
                return 'start';
            }
       },
       formatExtension,
      checkMessageType(message){
        const val = message.message.Type;
        let messageType = "TextMessage";
        switch(val){
            case "send_from_api":
                if(message.message.Body.Url){
                  switch(this.formatExtension(message.message.Body.FileName)){
                      case 'ogg' :
                          messageType = 'AudioMessage';
                        break;
                      case "pdf" :
                          messageType = "FileMessage";
                          break;
                      case "doc":
                          messageType = "FileMessage";
                        break;
                      case "docx":
                          messageType = "FileMessage";
                      break;
                      case "xls":
                          messageType = "FileMessage";
                      break; 
                      case "xlsx":
                          messageType = "FileMessage";
                      break; 
                      case "txt":
                          messageType = "FileMessage";
                      break; 
                      case "csv":
                          messageType = "FileMessage";
                      break;  
                      case "png":
                          messageType = "ImageMessage";
                        break;
                      case "jpg":
                          messageType = "ImageMessage";
                      break;
                      case "jpeg":
                          messageType = "ImageMessage";
                      break;
                      case "jpe":
                          messageType = "ImageMessage";
                      break;
                      case "jfif":
                          messageType = "ImageMessage";
                      break;
                      default: 
                          messageType = "ApiMessage";
                  }
                }else {
                  messageType = 'TextMessage';
                }
              break;
            case "receveid_video_message": 
                messageType = "VideoMessage";
              break;
            case "receveid_document_message":
                messageType = "FileMessage";
              break;
            case "send_document_message":
                  messageType = "FileMessage";
              break;
            case "receveid_image_message":
                 messageType = "ImageMessage";
              break;
            case "send_image_message":
               messageType = "ImageMessage";
              break;
            case "receveid_audio_message":
                messageType = "AudioMessage";
              break;
            case "send_audio_message":
                messageType = "AudioMessage";
              break;
            default:
                messageType = 'TextMessage'
        }
        return messageType;
      },
    scrollToBottom(){
            this.elem = document.getElementById("scrolled-content");
            this.container = document.getElementById("scroll-target");

            if(this.elem){
                this.container.scrollTop = Math.floor(this.elem.offsetHeight);
            }  
       },
    scrollToMessage(message_id){
      document.getElementById(message_id).scrollIntoView();
    },
    openLeftChats(){
      if(window.innerWidth < 460){
         document.querySelector('.left-content').style.display = "block";
         document.querySelector('#open-content-button').style.display = "none";
         document.querySelector("#mainContent").classList.add('hidden');
          this.setAppBar(true);
      }
    },
    closeLeftChats(){
         if(window.innerWidth < 460){
          document.querySelector('.left-content').style.display = "none";
          document.querySelector('#open-content-button').style.display = "flex";
          document.querySelector("#mainContent").classList.remove('hidden');
          this.setAppBar(false);
         }
    },
    mainContent(val){
       if(window.innerWidth < 460){
          if(val){
            document.querySelector('#open-content-button').style.display = "none";
            document.querySelector('#right-content').classList.remove('hidden-right');
            document.querySelector("#mainContent").classList.add('hidden');
         }else {
            document.querySelector('#open-content-button').style.display = "flex";
            document.querySelector('#right-content').classList.add('hidden-right');
            document.querySelector("#mainContent").classList.remove('hidden');
         }
       }else {
          if(val){
              document.querySelector('#right-content').classList.remove('hidden-right');
          }else {
             document.querySelector('#right-content').classList.add('hidden-right');
          }
       }
  },
  handleResize(){
      this.windowWidth = window.innerWidth;
  },
    },
    computed: {
    ...mapGetters({
        user: 'auth/user',
        currentMessages: 'message/currentMessages',
        loadingMessages: 'message/loadingMessages',
        currentChatUser: 'chat/currentChatUser',
        showUserProfile: 'rightcontent/showUserProfile',
        showSearchMessages: 'rightcontent/showSearchMessages',
        showChatConfig: 'rightcontent/showChatConfig',
        showChatsInfo: 'rightcontent/showChatsInfo',
        showMessagesData: "rightcontent/showMessagesData",
        showRightFromMenuTop: 'rightcontent/showRightFromMenuTop'
      }),
    showRightContent(){
        return this.showSearchMessages || this.showUserProfile || this.showChatConfig || this.showChatsInfo || this.showMessagesData;
   },
   containerHeight(){
     return this.windowWidth < 460 ? "104vh" : "90vh"
   },
    checkCurrentChat(){
        if(this.currentChatUser !== null){
            if(!this.currentChatUser.user){
                return 4;
            }else if(this.currentChatUser.status === 'E'){
                return 3;
            }else if(this.user.id !== this.currentChatUser.user.id){
                return 2;
            }else {
                return 1;
            }
        }
        return 1
      },
    messagesWithDates(){
          const messagesWithDates = [];
          this.currentMessages.map(item => {
             const date = this.showMessageDate(item);
             //console.log(date);
              if(date){
                 messagesWithDates.push({
                    message: { Body: { 
                          Info: { FromMe: false },
                          Text: date
                        },
                        Type: "receveid_message",
                      },
                    isDateMessage: true
                 })
                 messagesWithDates.push(item);
              }else {
                 messagesWithDates.push(item);
              }
          });

          return messagesWithDates;
      }
    },
    watch: {
        currentMessages(){
            this.currentMessageDate = null;
        },
        showRightContent(val){

           this.mainContent(val);
        },
        showRightFromMenuTop(val){
           if(window.innerWidth < 460){
            if(val){
               this.closeLeftChats();
               this.mainContent(val);
            }else {
            this.openLeftChats();
            document.querySelector('#right-content').classList.add('hidden-right');
            }
           }
        }
    },
    beforeMount(){
      this.loadChats();
    },
    updated(){
      this.scrollToBottom();
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>
<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  width: 98%;
  height: calc(80vh - 80px);
  border: 1px solid #ddd;
  border-radius: 16px;
}
.message-text{
   font-size: 16px;
}

#open-content-button{
  width: 20px;
  height: 35px;
  background: #1976D2;
  border-radius: 0 8px 8px 0;
  position: absolute;
  left: 0;
  top: 28px;
  z-index: 1;
  display: flex;
  justify-content: center;
}


#open-content-button
{
    display: none;
}


.text-card-title {
     font-size: 18px;
     align-self: center;
     text-align: center;
     text-transform: capitalize;
   }

.hidden-right {
     display: none;
}
@media (max-width: 460px)
{
   .hidden {
     display: none;
   }
   .text-card-title {
      font-size: 14px;
   }
   .message {
     width: 94%;
     height: calc(90vh - 80px);
   }
}

@media (max-width:900px) and (min-width:460px) {
  .message {
     width: 96%;
   }
}
</style>

<template>
    <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Home',
    methods:  {
      ...mapActions({
            checkAuthentication: 'auth/checkAuthentication',
            loadChats: 'chat/loadChats',
            startSocket: 'websocket/startSocket',
        }),
       redirectToLogin(){
         this.$router.push({ name: "Login"});
       },
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
        authenticated: 'auth/authenticated',
        phoneConnection: 'websocket/phoneConnection'
      }),
    },
    watch: {
      authenticated(val){
        //console.log("Authenticated", val);
         if(!val){
            this.redirectToLogin();
         }else {
             if(this.$route.name === 'ChatPage'){
                this.loadChats();
             }else {
                 this.$router.push({name: "ChatPage"});
             }
         }
      },
      phoneConnection(val){
        //console.log("Phone Connection", val);
           if(val){
             this.startSocket();
           }
      }
    },
    beforeMount(){
      this.checkAuthentication();
    },
    mounted(){
       if(!this.authenticated){
          this.redirectToLogin();
       }
    }
}
</script>
<style >
</style>

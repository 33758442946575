function formatPhoneNumber(number){
   const pattern = "+## (##) #####-####";

   let i = 0;
   const padded = pattern.replace(/#/g, () => {
      return number[i++];
   });
   return padded;
}


export default formatPhoneNumber;
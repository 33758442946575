import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/LoginPage.vue';
import Config from '../views/Config.vue';
import ChatPage from '../views/Chat.vue';
import Report from '../views/Report.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      { path: '/chat', name: "ChatPage", component: ChatPage },
      { path: '/report', name: 'Report', component: Report},
      { path: '/config', name: 'ConfigPage', component: Config}
    ]
    
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router

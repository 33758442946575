<template>
  <div class="text-center">
      <v-card>
        <v-list two-line>
        <v-list-item-group
           v-model="selectedItem"
           color="primary"
         >
          <template v-for="(item, index) in usersData">
            <v-subheader
              v-if="item.header"
              :key="item.header"
            >
              {{ item.header }}
            </v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index * 100"
              :inset="item.inset"
            ></v-divider>
            <v-list-item
              v-else
              :key="item.id"
               @click="
                setSelectedUser(item)
               "
            >
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="item.login"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        </v-list>
       <v-card-actions v-if="selectedUser">
           <v-spacer></v-spacer>
          <p>Transferir chat para <strong>{{ selectedUser.name }}</strong> ?</p>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
            $emit('showDialog',false);
            selectedUser = null;
            "
          >
            Não
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              transferChat(selectedUser);
              selectedUser = null;
              $emit('showDialog',false);
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
  </div>
</template>
<script>
import api from '../../services/api';
import { mapActions } from 'vuex';
export default {
    name: "TransferUsers",
    data(){
        return {
            users: [],
            selectedItem: 0,
            selectedUser: null
        }
    },
    computed: {
        usersData(){
            let data = [];
            data.push({ header: 'Usuarios' });
            this.users.map(item => {
                data.push(item);
                data.push({ divider: true, inset: true });
            })
            return data;
        },
    },
    methods: {
        ...mapActions({
            transferChat: 'chat/transferChat'
        }),
        async loadUsers(){
            const response = await api.get('/users');

            this.users = response.data;
        },
        setSelectedUser(item){
            this.selectedUser = item;
        }
    },
    mounted() {
        this.loadUsers();
    },
}
</script>
<template>
    <div>
      <v-dialog
      v-model="dialog"
      width="500"
    >
    <v-alert
      border="top"
      colored-border
      type="info"
      elevation="1"
    >
      <h3 class="text-title"> Leia QR Code abaixo no WhatsApp para reconectar</h3>
    </v-alert>
       <img :src="qrCode" >
    <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
          Fechar
    </v-btn>
    </v-dialog>
    <!-- <v-dialog
      hide-overlay
      v-model="qrCodeAlert"
      persistent
      width="280"
      
    > -->
      <v-alert
        border="top"
        colored-border
        type="info"
        elevation="1"
        class="alert-style d-none d-sm-flex"
        v-if="qrCodeAlert"
    >
     <div class="alert-text">
         <h3 class="text-title">Celular Desconectado</h3>
      <v-btn
            color="primary"
            text
            @click="openQrCodeModal"
          >
         <span class="text-title">Gerar QR CODE</span>
    </v-btn>
     </div>
    </v-alert>
    <!-- </v-dialog> -->
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    name: "QrCodeModal",
    data(){
      return {
        dialog: false
      }
    },
    computed: {
      ...mapGetters({
        qrCode: "websocket/qrCode",
        qrCodeAlert:"websocket/qrCodeAlert"
      })
    },
    methods: {
      ...mapActions({
         generateQrCode: "websocket/generateQrCode",
         closeQrCodeAlert: "websocket/closeQrCodeModal"
      }),
      async openQrCodeModal(){
          await this.generateQrCode();
      }
    },
    watch: {
      qrCodeAlert(val){
        if(!val){
           this.dialog = false;
        }
      },
      qrCode(val){
         if(val){
             this.dialog = true;
         }
      }
    }
}

</script>
<style scoped>
.text-title{
  text-align: center;
}
.alert-style{
  position: absolute;
  top: 60px;
  right: calc(50% - 140px);
  width: 280px;
  z-index: 200;
}
.alert-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
<template>
  <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-top-transition"
        max-width="500"
        :persistent="alertMessage.type === 'error' ? true : false"
      >
    <v-alert
      border="left"
      colored-border
      :value='dialog'
      :type="alertMessage.type"
      elevation="1"
      class="alert-style"
      :dismissible="alertMessage.type === 'error' ? true : false"
      @input="close"
    >
      <p v-html="alertMessage.message"></p>
    </v-alert>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "CustomAlert",
    data(){
      return {
        dialog: false
      }
    },
    computed: {
        ...mapGetters({
            alertMessage: 'alertMessage',
            showAlert: 'showAlert',
        }),
    },
    methods: {
       ...mapActions({
         closeAlert: 'closeAlert'
       }),
       close (value) {
          if(!value){
            this.closeAlert();
          }
      }
    },
    watch: {
       showAlert(val){
           this.dialog = val;
       }
    }
}
</script>
<style scoped>
.alert-style{
  position: absolute;
  top: 100px;
  max-width: 500px;
}

@media (max-width: 460px)
{
  .alert-style{
  position: absolute;
  top: 100px;
  left: 0;
}
}
</style>
import api from '../../services/api';
import { checkMessageWithError } from './utils/messageWithError';

export async function loadMessages({ commit, dispatch, getters, rootGetters },chatdata){
        commit('SET_ISLOADING', true);
        const checkNotEmpty = (obj) => obj && Object.keys(obj).length !== 0;
        try {
           const response = await api.post('/chatMessages', {
               number: checkNotEmpty(chatdata) ? chatdata.profile.number : ''
           });
           const { data } = response;
           //console.log("Mensagems Atuais", data);
           commit('SET_MESSAGES', data);
           commit('SET_ISLOADING', false);

           dispatch('chat/updateCurrentChat', null , { root: true });

           if(checkNotEmpty(chatdata)) {
           dispatch('chat/updateCurrentChat', chatdata , { root: true });

           const newMessages = getters.newMessages;
           const newChatData = rootGetters['chat/newChatData'];

           newMessages[chatdata.id] = [];
        
           newChatData[chatdata.id] = null;

           commit('SET_NEW_MESSAGES', newMessages);

           }
           //console.log("Load Chat Data");
        } catch (error) {
            if(error.response){
                if(error.response.status === 401){
                   dispatch("showErrorAlert" ,error.response.data.message , { root: true });
                   dispatch("auth/signOut",{},{ root: true} );
                }else {
                   dispatch("showErrorAlert" ,error.response.data.message , { root: true });
                }
            }else {
                console.log(error);
                dispatch("showErrorAlert" ,"Falha ao Carregar Mensagems", { root: true });
            } 
            commit('SET_ISLOADING', false);
        }
  }
export async function sendMessage({ rootGetters, dispatch, getters, commit }, text){

    const chatNumber = rootGetters['chat/currentChat'];
    const messages = getters.currentMessages;
    const user = rootGetters['auth/user'];

    const hasMessageWithTextError = checkMessageWithError(messages,text);

    if(hasMessageWithTextError){
        commit('SET_RESEND_LOADING', true);
    }

    let newMessage = {
        "message_type":"text",
        "number": chatNumber,
        "message": text
    }
    
    try {
        //console.log(newMessage);
        await api.post('/sendMessage', newMessage);

        commit('SET_RESEND_LOADING', false);

   } catch (error) {
        if(error.response){
            dispatch("showErrorAlert" ,`"Falha no envio da Mensagem " ${error.response.data.message}` , { root: true });
            console.log(error.response);
        }else {
            dispatch("showErrorAlert" ,"Falha no envio da Mensagem", { root: true });
            console.log(error);
        }
        const messageWithError = {
            user: {
                user_name: user.name
            },
            message: { Body: { 
                   Info: { FromMe: true },
                   Text: `${text}`
                 },
                 Type: "send_from_api"
               },
            messageWithError: true,
            date: new Date().getTime()
        };

        console.log(messageWithError);
        
        // verifica se tem mensagem com com erro com o mesmo texto e atualiza ele se tiver; 
        const hasMessageWithTextError = checkMessageWithError(messages,text);

        if(!hasMessageWithTextError){

            messages.push(messageWithError);

            commit('SET_MESSAGES',messages);
        }
        commit('SET_RESEND_LOADING', false);
   }
  }

  export async function sendFileMessage({ dispatch}, fileMessage){
      try {
          await api.post('/sendMessage', fileMessage);
      } catch (error) {
        if(error.response){
            dispatch("showErrorAlert" ,`Falha ao Enviar Arquivo ${error.response.data.message}`, { root: true });
        }else {
            console.log(error);
            dispatch("showErrorAlert" ,"Falha ao Enviar Arquivo", { root: true });
        } 
      }
  }

  export async function loadClosedChatMessages({ commit, dispatch },chatdata){
       
    const searchParam = chatdata.id.length > 14 ? {id: chatdata.id } : {number: chatdata.id };

    try {
         const response = await api.post('/allChatMessages', searchParam );

         //console.log(response.data);

         commit('SET_MESSAGES',response.data);

         dispatch('chat/updateCurrentChat', chatdata , { root: true });
    
    } catch (error) {
        if(error.response){
            dispatch("showErrorAlert" ,error.response.data.message, { root: true });
        }else {
            console.log(error);
            dispatch("showErrorAlert" ,"Falha ao Carregar Mensagems", { root: true });
        } 
    }
}

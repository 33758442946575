export default function formatMessageNumber(stringNumberToFormat){

    // return an array that contains the matches
    const formatedMessageNumber = stringNumberToFormat.match(/\d+/g);

    // transform string to array
    const messageNumberArray = formatedMessageNumber[0].split('');

    // add 9 to the number after 5564
    messageNumberArray.splice(4,0,'9');

    return  messageNumberArray.join('');
}
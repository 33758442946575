<template>
<v-menu
           v-model="menuFilter"
            bottom
            left
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="blue">mdi-calendar-search</v-icon>
              </v-btn>
            </template>
            <v-container class="pa-2">
    <v-container class="d-flex flex-row justify-space-between">
            <p class='ml-4 mt-2 grey--text text--darken-3'>Buscar Por Data</p>
            <v-btn
              icon
              @click="menuFilter = false"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
      <v-date-picker
        v-model="dates"
        range
        locale="pt-BR"
        :max="maxDate"
      ></v-date-picker>
      <v-container>
    <v-text-field
        v-model="dateRangeText"
        label="Data Selecionada"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
       <v-text-field
        v-model="user"
        label="Usuário"
        placeholder="Login do usuário"
      ></v-text-field>
          <v-btn
           width="100%"
           outlined
           elevation="0" 
           color="#1976D2"
           class="text-capitalize"
           @click="menuFilter = false; loadChatsByDate({ dates, user});"
          >
          Procurar
      </v-btn>
      </v-container>
  </v-container>
</v-menu>
</template>
<script>

import { mapActions } from 'vuex';
import formatDate from "../utils/formateDate";

  export default {
    name: "MenuDatePickerSearch",
    data: () => ({
      dates: [formatDate(new Date(),"-", true)],
      user: '',
      menuFilter: false
    }),
    methods: {
    ...mapActions({
      loadChatsByDate: 'chat/loadChatsByDate',
    }),  
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
      maxDate(){
          const today = new Date();

          return formatDate(today,"-", true);
        },
    },
  }
</script>

<style scoped>

.container{
    background-color: #fff;
}
</style>
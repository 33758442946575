<template>
     <v-container class="pa-0 overflow-x-hidden max-height-container">
        <v-container class="d-flex flex-row justify-space-between">
            <p @click="loadStatistic" class='ml-4 mt-2 grey--text text--darken-3'>Atendimentos</p>
            <v-btn
              icon
              @click="closeRightContent"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
        <v-container>
            <MenuDatePickerRange @onPress="loadStatistic" />
        </v-container>
            <template v-for="(item, index) in dataInfo" >
             <v-container
               :key="item.title"
               class="d-flex flex-row justify-space-between align-center" 
            >
                <p class="font-weight-bold">{{item.value}}</p>
                <p class="subtitle-2">{{item.title}}</p>
             </v-container>
             <v-divider :key="index"></v-divider>
        </template>
     </v-container>
</template>
<script>
import api from '../../services/api';
import { mapActions } from 'vuex';
import MenuDatePickerRange from '../MenuDataPickerRange.vue';
import arrayDatesToObj from '../../utils/arrayDatesToObj';

export default {
    name: "ChatsDataInfo",
    data(){
        return {
            dataInfo: [
                {status: "chats_waiting_attendance", title: "Em Espera", value: "0"},
                {status: "chats_in_attendance", title: "Em Atendimento", value: "0"},
                {status: "chats", title: "Atendimentos", value: "0"},
                {status: "messages", title: "Mensagens", value: "0"}
            ]
        }
    },
    components: {
        MenuDatePickerRange
    },
    methods: {
        ...mapActions({
            closeRightContent: 'rightcontent/closeRightContent',
             showErrorAlert: "showErrorAlert"
        }),
        async loadStatistic(dates){
            try {
                if(Array.isArray(dates)){
                    const searchParams = arrayDatesToObj(dates);

                    const response = await api.post('/statistics', searchParams);

                    this.updateStatistics(response);

                }else {
                     
                    const response = await api.get('/statistics');

                    this.updateStatistics(response);
                }
            } catch (error) {
                if(error.response){
                     this.showErrorAlert(error.response.data.message);
                }else {
                    this.showErrorAlert("Falha ao carregar estatísticas");
                }
                console.log(error);
            }
        },
        updateStatistics(response){
            let updatedDataInfo = this.dataInfo.map(item => {
                    if(item.status === "chats") return {...item, value: response.data.chats}
                    else if(item.status === "chats_in_attendance") return {...item, value: response.data.chats_in_attendance}
                    else if(item.status === "chats_waiting_attendance") return {...item, value: response.data.chats_waiting_attendance}
                    else if(item.status === "messages") return {...item, value: response.data.messages}
                    else return item;
                });
            this.dataInfo = updatedDataInfo;
        },
    },
    mounted(){
        this.loadStatistic();
    },
}
</script>


export default function updateMessagesArray(messages, updatedMessage ){

    const updatedMessages = messages.map(item => {
        if(item.id === updatedMessage.id){
            return updatedMessage;
        }else {
            return item;
        }

    });

    return updatedMessages;
}
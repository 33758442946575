<template>
    <v-container fluid class="screen-size-main-container">
    <v-btn @click="goBack">Voltar</v-btn>
    <h1>Relatório</h1>
    <v-row>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="date1"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date1"
            label="Data Inicial"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date1"
          no-title
          scrollable
          locale="pt-br"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(date1)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="date2"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date2"
            label="Data Final"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date2"
          no-title
          scrollable
          locale="pt-br"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date2)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
        <v-btn
        elevation="2"
        class="mt-4"
        @click="getReport"
        >
        Procurar
        </v-btn>
        <v-btn
        elevation="2"
        class="ml-4 mt-4"
        @click="reportData = []"
        >
        Limpar
        </v-btn>
        <v-btn
        elevation="2"
        class="ml-4 mt-4"
        @click="downloadCSV"
        >
        Exportar CSV
        </v-btn>
    </v-col>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="reportData"
        :items-per-page="20"
        class="elevation-1"
    ></v-data-table>
    </v-container>
</template>
<script>
import api from '../services/api';

export default {
    name: "Report",
    data() {
        return {
            menu1: false,
            menu2: false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            start_date: "",
            end_date: "",
            reportData: []
        }
    },
    computed: {
        headers(){
            return [
            {
            text: 'Instância Id',
            align: 'start',
            sortable: false,
            value: 'instance_id',
          },
          { text: 'Instância', sortable: false,  value: 'instance' },
          { text: 'Messagem Id', sortable: false, value: 'message_id' },
          { text: 'Número', sortable: false, value: 'number' },
          { text: 'Data', sortable: false, value: 'date' },
          { text: 'Tag',  sortable: false, value: 'tag' },
          { text: 'Crédito',  sortable: false, value: 'credit' },
          { text: 'Fonte',  sortable: false, value: 'source' },
            ]
        }
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        },
        convertTOCSV(){
            var json = this.reportData;
            var header = ["Instância Id","Instância","Messagem Id","Número","Data","Tag","Crédito"];
            var fields = Object.keys(json[0]);
            var replacer = function(key, value) { return value === null ? '' : value } 
            var csv = json.map(function(row){
            return fields.map(function(fieldName){
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
            })
            csv.unshift(header.join(',')) // add header column
            csv = csv.join('\r\n');
            return csv;
        },
        downloadURI(uri, name){
            var link = document.createElement("a");
            // If y    let link = document.createElement("a");
            link.download = name;
            link.href = uri;
            link.click();
        },
        downloader(data, type, name) {
            let blob = new Blob([data], {type});
            let url = window.URL.createObjectURL(blob);
            // console.log(url);
            this.downloadURI(url, name);
            window.URL.revokeObjectURL(url);
        },
        downloadCSV(){
            const data = this.convertTOCSV();
            this.downloader(data,'csv',`${this.date1}-${this.date2}.csv`);
        },
        async getReport(){
            try {
                const response = await api.post('/messagesReport', {
                    start_date: this.date1,
                    end_date: this.date2
                })
                this.reportData = response.data;
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
<style scoped>
     
</style>

  
import Vuex from "vuex";
import Vue from 'vue';
import auth from './auth';
import chat from './chat';
import message from './message';
import websocket from './websocket';
import rightcontent from './rightcontent';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        alertMessage: { message: "", type: "info" },
        showAlert: false,
        showAppBar: true,
        notificationSound: false
    },
    getters: {
        showAlert(state){
            return state.showAlert;
        },
        alertMessage(state){
            return state.alertMessage;
        },
        showAppBar(state){
            return state.showAppBar;
        },
        notificationSound(state){
            return state.notificationSound;
        }

    },
    mutations: {
        SET_ALERT_MESSAGE(state, value){
            state.alertMessage = value;
        },
        SET_SHOW_ALERT(state, value){
           state.showAlert = value;
        },
        SET_SHOW_APPBAR(state,value){
            state.showAppBar = value;
        },
        SWITCH_NOTIFICATION_SOUND(state){
            state.notificationSound = !state.notificationSound;
        }
    },
    actions: {
        showAlertMessage({ commit } , messageText){
            commit("SET_SHOW_ALERT", true);
            commit("SET_ALERT_MESSAGE", messageText);
            setTimeout(() => {
                commit("SET_SHOW_ALERT", false);
            },5000);
        },
        showErrorAlert({ commit }, message){
            commit("SET_SHOW_ALERT", true);
            commit("SET_ALERT_MESSAGE", { message: message, type: "error" });
        },
        closeAlert({commit}){
            //console.log("is Closing Alert");
            commit("SET_SHOW_ALERT", false);
        },
        setAppBar({ commit },value){
           commit("SET_SHOW_APPBAR", value);
        },
        switchNotificationSound({ commit }){
             commit('SWITCH_NOTIFICATION_SOUND');
        }
    },
    modules: {
        auth,
        chat,
        message,
        websocket,
        rightcontent
    }
})


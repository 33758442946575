<template>
    <v-container fluid class='pa-0 screen-size-main-container'>
      <div class="border-top-appbar"></div>
      <v-app-bar
      v-if="showAppBar"
      elevation="0"
      color="#F5F5F5"
      max-height="73"
      id="app-bar"
    >
      <div class="d-flex align-center">
         <img :src="logo" alt="">
      </div>
      <div class="ml-4" v-if='authenticated && user.clients.length > 1'>
          <p class="mt-4 text-subtitle-1 grey--text text-darken-1">{{ user.selectedClient.client_name }}</p>
      </div>
      <v-spacer></v-spacer>
      <div class="d-none d-sm-flex" v-if='authenticated && user.clients.length > 1'>
              <v-btn 
              text color="#1976D2"  
              @click="showDialogChangeClient({show: true, clients: user.clients })"
              >
                <v-icon>
                  mdi-swap-horizontal 
                </v-icon>
                <span class="icons-text">Alterar Cliente</span>
            </v-btn>
      </div>
      <div class="d-none d-sm-flex" v-if='authenticated'>
        <NewChatInput />
      </div>
      <div  class="d-none d-sm-flex" v-if='authenticated'>
        <Disconnect />
      </div>
      <div  class="d-none d-sm-flex" v-if='authenticated'>
        <v-btn text @click="showRightContent('ChatsData')">
              <img :src="statistics" alt="">
              <span class="icons-text">Atendimentos</span>
        </v-btn>
      </div>
      <div  class="d-none d-sm-flex" v-if='authenticated'>
        <v-btn text @click="$router.push({ name: 'Report'})" color="#1976D2">
              <v-icon>mdi-view-list-outline</v-icon>
              <span class="icons-text">relatório</span>
        </v-btn>
      </div>
      <div  class="d-none d-sm-flex" v-if='authenticated'>
        <v-btn text @click="showRightContent('MessagesData')" color="#1976D2">
              <v-icon>
                mdi-chart-pie
              </v-icon>
              <span class="icons-text">Disparos</span>
        </v-btn>
      </div>
      <div  class="d-none d-sm-flex" v-if='authenticated'>
        <v-btn text @click="switchNotificationSound()" color="#1976D2">
              <v-icon v-if="notificationSound">
                mdi-bell
              </v-icon>
              <v-icon v-else>
                mdi-bell-off
              </v-icon>
        </v-btn>
      </div>
      <div class="d-none d-sm-flex" v-if='authenticated'>
        <v-btn text @click='signOut' color="#1976D2" class="pr-1">
          <v-icon>
            mdi-logout 
          </v-icon>
          <p class="text-capitalize ma-0 pa-0">Sair</p>
      </v-btn>
      </div>
      <div class="d-flex d-sm-none" v-if="authenticated">
           <v-menu offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    class="mr-n4"
                  >
                   <v-icon>
                     mdi-menu
                   </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="pa-0">
                  <v-list-item
                  class="pa-0"
                  v-if='authenticated && user.clients.length > 1'
                  >  
                     <v-btn 
                      color="#1976D2"
                      text  
                      @click="showDialogChangeClient({show: true, clients: user.clients })"
                      >
                     <v-icon>
                      mdi-swap-horizontal 
                     </v-icon>
                     <span class="icons-text">Alterar Cliente</span>
                   </v-btn>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <NewChatInput />
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <Disconnect />
                  </v-list-item>
                  <v-list-item
                  class="pa-0"
                  >  
                     <v-btn text @click="showRightContent('ChatsData')">
                     <img :src="statistics" alt="">
                     <span class="icons-text">Atendimentos</span>
                   </v-btn>
                  </v-list-item>
                  <v-list-item
                  class="pa-0"
                  >  
                     <v-btn text @click="showRightContent('MessagesData')" color="#1976D2">
                     <v-icon>
                        mdi-chart-pie
                     </v-icon>
                     <span class="icons-text">Disparos</span>
                   </v-btn>
                  </v-list-item>
                  <v-list-item
                    class="pa-0"
                    >  
                    <v-btn text @click="switchNotificationSound()" color="#1976D2">
                        <v-icon v-if="notificationSound">
                          mdi-bell
                          </v-icon>
                          <v-icon v-else>
                          mdi-bell-off
                          </v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item
                   class="pa-0"
                  >
                     <v-btn v-if='authenticated' text @click='signOut' color="#1976D2" class="pr-1" >
                     <v-icon>
                        mdi-logout 
                     </v-icon>
                     <p class="text-capitalize ma-0 pa-0">Sair</p>
                </v-btn>
                  </v-list-item>
                </v-list>
            </v-menu>
      </div>
    </v-app-bar>
    <DialogChangeClient />
    </v-container>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import novaxslogo from '../assets/logo_novaxs.svg'
import statistics from '../assets/statistics.svg'
import NewChatInput from './NewChatInput.vue';
import DialogChangeClient from './dialogs/DialogChangeClient.vue'
import Disconnect from './Disconnect.vue';

export default {
  name: 'AppBar',

  data: () => ({
      logo: novaxslogo,
      statistics: statistics
  }),
  components: {
    NewChatInput,
    DialogChangeClient,
    Disconnect
},
  methods: {
    ...mapActions({
            signOut: 'auth/signOut',
            showRightContent: 'rightcontent/showRightContent',
            showDialogChangeClient: 'auth/showDialogChangeClient',
            switchNotificationSound: 'switchNotificationSound'
    }),
  },
  computed: {
    ...mapGetters({
         authenticated: 'auth/authenticated',
         notificationSound: 'notificationSound',
         showAppBar: 'showAppBar',
         user: 'auth/user'
    })
  },
};
</script>
<style scoped>
.border-top-appbar {
  width: 100%;
  height: 5px;
  background-color: #1976D2;
}

.icons-text {
  font: normal normal normal 11px/12px Roboto;
  letter-spacing: 0px;
  color: #262A2E;
  opacity: 1;
  text-transform: capitalize;
  margin-top: 5px;
}
#img-size {
  width: 20px;
}
</style>
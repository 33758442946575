import * as methods from './methods';
import * as requests from './requests';

export default {
    namespaced: true,
    state: {
        currentMessages: [],
        loadingMessages: false,
        resendLoading: false,
        newMessages: {},
        isTypingMessage: {}
    },
    getters: {
        loadingMessages(state){
            return state.loadingMessages;
        },
        currentMessages(state){
            return state.currentMessages;
        },
        newMessages(state){
            return state.newMessages;
        },
        resendLoading(state){
            return state.resendLoading;
        },
        isTypingMessage(state){
            return state.isTypingMessage;
        }
    },
    mutations: {
        SET_ISLOADING(state, value){
            state.loadingMessages = value;
        },
        SET_MESSAGES(state, messages){
            state.currentMessages = messages;
        },
        SET_NEW_MESSAGES(state, messages){
            state.newMessages = messages;
        },
        SET_RESEND_LOADING(state,value){
            state.resendLoading = value;
        },
        SET_ISTYPING_MESSAGE(state,value){
            state.isTypingMessage = value;
        }
    },
    actions: {
        ...requests,
        ...methods
    }
}

import api from '../../services/api';
import checkUserActiveChat from './utils/checkUserActiveChat';
import formatStartEndDates from './utils/formatStartEndDates';
import arrayDatesToObj from '../../utils/arrayDatesToObj';

export async function beginChat({ getters, dispatch }){
    try {
        await api.post('/beginChat', {
          id: getters.currentChatUser.id
        });

        const ChatProfile = getters.currentChatUser.profile;
        const userName = ChatProfile.name ? ChatProfile.name : ChatProfile.number;

        const alertMessage = {
            message: `Atendimento iniciado a ${userName}`,
            type: "success"
        }
        dispatch("showAlertMessage" , alertMessage , { root: true });
    } catch (error) {
        if(error.response){
           dispatch("showAlertMessage" ,{ message: error.response.data.message, type: "info" } , { root: true });
        }else {
            dispatch("showErrorAlert" ,"Erro ao iniciar chat", { root: true });
           console.log(error);
        }
    }
}
export async function closeChat({ getters, dispatch }, send_message){
    try {
        await api.post('/closeChat', {
            id: getters.currentChatUser.id,
            send_message: send_message

        });
        //dispatch("showAlertMessage" ,{ message: 'Chat Encerrado', type: "warning" } , { root: true });
     } catch (error) {
         if(error.response){
            dispatch("showErrorAlert" ,error.response.data.message , { root: true });
         }else {
            dispatch("showErrorAlert" ,"Erro ao fechar o chat", { root: true });
           console.log(error);
         }
     }
}


export async function transferChat( { getters, dispatch } , user){
    try {
        await api.post("/transferChat", {
         "id":  getters.currentChatUser.id ,
         "user_id": user.id
       });

       const ChatProfile = getters.currentChatUser.profile;
       const userName = ChatProfile.name ? ChatProfile.name : ChatProfile.number;

       const alertMessage = {
           message: `Atendimento a ${userName} transferido para usuario${user.name}`,
           type: "info"
       }
         dispatch("showAlertMessage" , alertMessage , { root: true });
    } catch (error) {
        if(error.response){
           dispatch("showErrorAlert" ,error.response.data.message , { root: true });
           console.log(error.response.data);
        }else {
            dispatch("showErrorAlert" ,"Erro ao Transferir chat", { root: true });
        }
    }
}
export async function loadChatsByDate({ commit, dispatch },params){
    try {
    const {dates, user } = params;
    if(Array.isArray(dates)){
            let searchDates = arrayDatesToObj(dates);

            if(user)searchDates.user = user;

            const response = await api.post("/allChats", searchDates);
            
            commit("SET_CHATS_BY_DATES", response.data);
    }else {
        const { startDate, endDate } = formatStartEndDates('months', 3);

             const allChatsByDate = await api.post("/allChats", {
                     "start_date": endDate,
                     "end_date": startDate,
             });
     
             const closedChats = allChatsByDate.data.filter(item => item.status === "E");
         
             commit("SET_CLOSED_CHATS", closedChats);
    }

   } catch (error) {
    if(error.response){
        dispatch("showErrorAlert" ,error.response.data.message , { root: true });
     }else {
        dispatch("showErrorAlert" ,"Erro ao carregar chats", { root: true });
       console.log(error);
     }
   }

}

export async function loadChatsByNumber({ dispatch, getters, commit }){
    try {
        const response = await api.post('/allChats', {
              "number": `${getters.currentChatUser.profile.number}`
        });
        commit("SET_HISTORY_CHATS", response.data);
         
        } catch (error) {
            if(error.response){
                dispatch("showErrorAlert" ,error.response.data.message , { root: true });
             }else {
                dispatch("showErrorAlert" ,"Erro ao carregar chats", { root: true });
               console.log(error);
             }
        }
}

export function resetChatsAndMessages({ commit, dispatch, getters }){
    // console.log("Reset Chats");
    const chats = getters.chats;
    if(chats == [] || chats.length <= 0){
        commit("SET_CHATS", []);
        dispatch('message/loadMessages',{},{ root: true });
    }
}

export async function loadChats ({ commit, dispatch, getters, rootGetters }){

    const authUser = rootGetters['auth/user'];

    try {

    const response = await api.post('/chats');

    //console.log("response /chats", response);

    const oldActiveChat = getters.currentChatUser;

     if(Array.isArray(response.data) && response.data.length > 0){
        const hasActiveUserChat = checkUserActiveChat(response.data,authUser.id);
        
        let currentChatData = {};

        if(getters.currentChatUser !== null){

            const currentChatDataResponse = response.data.filter( item => item.profile.number === oldActiveChat.profile.number);

            if(Array.isArray(currentChatDataResponse) && currentChatDataResponse.length > 0){
                const { profile, user, id, status, short_text = '', follow_up = ''  } = hasActiveUserChat ? hasActiveUserChat : currentChatDataResponse[0];
    
                currentChatData = { profile, user, id, status, short_text, follow_up };
            }else {
                const { profile, user, id, status, short_text = '', follow_up = '' } = hasActiveUserChat ? hasActiveUserChat : response.data[0];
    
                currentChatData = { profile, user, id, status, short_text, follow_up };
            }
          }else {
    
            const { profile, user, id, status, short_text = '', follow_up = '' } = hasActiveUserChat ? hasActiveUserChat : response.data[0];
    
            currentChatData = { profile, user, id, status, short_text, follow_up };
          }
          commit("SET_CHATS", response.data);
          commit('SET_CURRENT_CHAT_USER',currentChatData);
          dispatch('message/loadMessages',currentChatData, { root: true });
     }else {
        commit("SET_CHATS", response.data);
        dispatch('message/loadMessages',{},{ root: true });
     }
        dispatch('websocket/checkConnectionStatus', {}, { root: true });
    } catch (error) {
       if(error.response){
         if(error.response.status === 401){
            dispatch("auth/signOut",{},{ root: true} );
            setTimeout(()=> {
                dispatch("showErrorAlert", error.response.data.message , { root: true });
            },1000);
         }else if(error.response.status === 500){
            dispatch("auth/signOut",{},{ root: true} );
            setTimeout(()=> {
                dispatch("showErrorAlert", error.response.data.message , { root: true });
            },1000);
         }
         else {
            dispatch("showErrorAlert", error.response.data.message , { root: true });
            dispatch('resetChatsAndMessages');
         }
       }else{
           dispatch("showErrorAlert" , "Falha ao Carregar Chats", { root: true });
           dispatch('resetChatsAndMessages');
       }
    }
}


import api from '../services/api';
export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        isLoading: false,
        dialogChangeClient: {show: false, clients: [] }
    },
    getters: {
       authenticated (state){
           return state.token && state.user
       },
       dialogChangeClient(state){
        return state.dialogChangeClient;
       },
       user (state){
           return state.user
       },
       token (state) {
           return state.token
       },
       isLoading( state) {
           return state.isLoading;
       }
    },
    mutations: {
        SET_DIALOG_CHANGE_CLIENT(state, status){
            state.dialogChangeClient = status
        },
        SET_LOADING_STATUS(state, status){
            state.isLoading = status;
        },
        SET_TOKEN (state, token){
            state.token = token;
        },
        SET_USER (state, user){
           state.user = user;
        }
    },
    actions: {
        showDialogChangeClient({ commit },value){
            commit('SET_DIALOG_CHANGE_CLIENT',value);
        },
        signInClient({ dispatch }, selectedClient ){

            dispatch('showDialogChangeClient', {show: false, clients: [] });

            const token = JSON.parse(localStorage.getItem('@token'));
            const user = JSON.parse(localStorage.getItem('@user'));

            const data = {
                token,
                user: {...user,selectedClient: selectedClient}
            }
            localStorage.setItem('@user', JSON.stringify(data.user));
            dispatch('websocket/closeSocket', {} , { root: true });
            dispatch('attempt', data);

        },
        async signIn({ dispatch, commit },credentials){
            commit('SET_LOADING_STATUS',true);
            try {
                let response = await api.post('/login', credentials);

               if(response.data.clients.length <= 0){
                  dispatch('showErrorAlert',"Usuario sem acesso a clientes", { root: true });
                  commit('SET_LOADING_STATUS',false);
                  return;
               }
                               
                const data = {
                    token: response.headers['x-token'],
                    user: {...response.data, selectedClient: response.data.clients[0]},
                }
                localStorage.setItem('@token',JSON.stringify(data.token));
                localStorage.setItem('@user', JSON.stringify(data.user));

               if(data.user.clients.length > 1){
                    dispatch('showDialogChangeClient', {show: true, clients: data.user.clients });
               }else {
                     dispatch('attempt', data);
               }
                
            } catch (error) {
                commit('SET_LOADING_STATUS',false);
                if(error.response){
                   if(error.response.data && error.response.data.message){
                    dispatch("showErrorAlert" ,error.response.data.message , { root: true });
                   }else {
                    dispatch("showErrorAlert" ,String(error.response.data) , { root: true });
                   }
                }else {
                    dispatch("showErrorAlert" ,"Ocorreu um erro ao tentar fazer login" , { root: true });
                }
            }
        },
        signOut ({ commit, dispatch }){
              commit('SET_TOKEN',null);
              commit('SET_USER', null);
              commit('websocket/SET_PHONE_CONNECTION', false, { root: true });
              localStorage.clear();
              dispatch('websocket/closeSocket', {} , { root: true });
        },
        checkAuthentication({ dispatch }){
            let token = localStorage.getItem('@token');
            let user = localStorage.getItem('@user');

            if(token && user){

                const data = {
                    token: JSON.parse(token),
                    user: JSON.parse(user),
                }
                dispatch('attempt', data);
            }
        },
        async attempt ({ commit }, data){
            
             commit('SET_TOKEN', data.token);
             commit('SET_USER', data.user);
             
             api.defaults.headers.common['ChatType'] = data.user.selectedClient.chat_types[0];
             api.defaults.headers.common['ClientId'] =  data.user.selectedClient.client_id;
             api.defaults.headers.common['Authorization'] = data.token;

             commit('SET_LOADING_STATUS',false);
        }
    },    
};

<template>
<v-container class="pa-0" >
       <v-sheet class="px-4 py-6 top-search-border">
        <div
            v-if="mediumSizeHandle"
            class="d-flex justify-center"
          >
           <v-btn outlined color="#1976D2" rounded  @click="showSearchBox = !showSearchBox">
              <v-icon size="24" color="#1976D2">
                 mdi-magnify
               </v-icon>
           </v-btn>
            <MenuDatePickerSearch />
            <div class="search-input-box" v-if='showSearchBox'>
              <v-btn text @click="showSearchBox = false" rounded>
                       <v-icon color="#1976D2">
                          mdi-close
                       </v-icon>
              </v-btn>
                      <v-text-field
                        hide-details="auto"
                        outlined
                        dense
                        rounded
                        label="Procurar Contato"
                        v-model="searchtext"
                        @keyup="searchData"
                     ></v-text-field>
            </div>
        </div>
        <v-container v-else class="pa-0 pr-1" >
          <v-row >
            <v-col cols="11"
                   sm="10"
                   md="11"  
                   class="pa-0">
                <v-text-field
                  class="ml-2"
                  hide-details="auto"
                  prepend-inner-icon="mdi-magnify"
                  rounded
                  outlined
                  dense
                  label="Procurar Contato"
                  v-model="searchtext"
                  @keyup="searchData"
              ></v-text-field>
            </v-col >
            <v-col cols="1"
                   sm="2"
                   md="1" 
                  class="pa-0"
            >
                <MenuDatePickerSearch />
            </v-col>
        </v-row>
        </v-container>
       </v-sheet>
    <v-card class="overflow-hidden" v-if='historyChats'>
            <v-app-bar
              absolute
              color="white"
              elevate-on-scroll
              scroll-target="#scrolling-techniques-7"
            >
            <v-toolbar-title>Histórico de {{profileName}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="cleanHistory">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-app-bar>
            <v-sheet
                  id="scrolling-techniques-7"
                  class="overflow-y-auto height-chats"
              >
              <Chats :chatsData="historyChats" @closeLeftContent="$emit('closeLeftContent')"/>
            </v-sheet>
    </v-card>
    <v-card class="overflow-hidden" v-else-if='chatsByDates'>
            <v-app-bar
              absolute
              color="white"
              elevate-on-scroll
              scroll-target="#scrolling-techniques-7"
            >
            <v-toolbar-title>Resultado</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="cleanChatsByDates">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-app-bar>
            <v-sheet
                  id="scrolling-techniques-7"
                  class="overflow-y-auto height-chats"
              >
              <Chats :chatsData="chatsByDates" @closeLeftContent="$emit('closeLeftContent')"/>
            </v-sheet>
    </v-card>
    <v-container v-else class="overflow-y-auto height-chats pa-0">
        <!-- <v-tabs v-model="tab" grow>
          <v-tab>Atendimentos</v-tab>
          <v-tab
           @click="loadChatsByDate"
          >Encerrados</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab"> -->
          <!-- <v-tab-item> -->
             <Chats :chatsData="chatsData" @closeLeftContent="$emit('closeLeftContent')"/>
          <!-- </v-tab-item> -->
          <!-- <v-tab-item>
             <Chats :chatsData="closedChatsData" @closeLeftContent="$emit('closeLeftContent')"/>
          </v-tab-item>
        </v-tabs-items> -->
    </v-container>
    </v-container>
</template>
<script>
import Chats from '../components/Chats';
import { mapGetters, mapActions } from 'vuex';
import api from '../services/api';
import MenuDatePickerSearch from './MenuDatePickerSearch.vue';
import useDebounce from '../utils/useDebounce';

export default {
  name: "LeftContent",
  data() {
      return {
        tab: null,
        searchtext: "",
        chatsData: [],
        closedChatsData: [],
        windowWidth: window.innerWidth,
        showSearchBox: false,
      }
  },
  components: {
     Chats,
     MenuDatePickerSearch
  },
  methods: {
    ...mapActions({
      loadChatsByDate: 'chat/loadChatsByDate',
      cleanHistory: 'chat/cleanHistory',
      cleanChatsByDates: 'chat/cleanChatsByDates',
    }),
     switchChat(id){
         this.$emit("handleSwitchChat",id);
   },
   searchDataDebounce(){},
   searchData(){
        this.chatsData = this.chats;

        if(this.searchtext !== "" && this.searchtext.length > 0){
          
        const formatedMessageNumber = this.searchtext.match(/\d+/g);

        if(!formatedMessageNumber){
             this.chatsData = this.chatsData.filter(item => {
                return item.profile.name && item.profile.name.toLowerCase().includes(this.searchtext.toLowerCase());
           });
         }else {
           this.searchDataDebounce(this.searchtext);
         }
        }
   },
   async searchChatsByNumber(searchNumber){
        try {
            const response = await api.post('/allChats', {
                  "number": searchNumber
            });
              this.chatsData = response.data;

            } catch (error) {
                console.log(error);
            }
   },
   handleResize(){
     this.windowWidth = window.innerWidth;
   },
},
computed: {
    ...mapGetters({
             chats: 'chat/chats',
             closedChats: 'chat/closedChats',
             historyChats: 'chat/historyChats',
             chatsByDates: 'chat/chatsByDates',
             currentChatUser: 'chat/currentChatUser',
        }),
    mediumSizeHandle(){
        return this.windowWidth < 960 && this.windowWidth > 460;
    },
    profileName(){

        const profile = this.currentChatUser.profile;

        return profile.name ? (profile.name.length > 12 && this.windowWidth <= 320 ) ? `${profile.name.substring(0, 12)}...` : profile.name
               : profile.number
      },
},
watch: {
     chats(val){
       this.chatsData = val;
     },
     closedChats(val){
       this.closedChatsData = val;
     }
},
 mounted(){
   //console.log("chats On Load", this.chats);
   this.chatsData = this.chats;
 },
  created() {
        window.addEventListener("resize", this.handleResize);
        this.searchDataDebounce = useDebounce(this.searchChatsByNumber,2000);
    },
  destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
}

</script>
<style scoped>

.height-chats {
   height: 82vh;
}
.top-search-border {
  border-bottom: 1px solid lightgray;
  position: relative;
}

.search-input-box {
  position: absolute;
  right: -150px;
  top: 20px;
  z-index: 999;
  width: 300px;
  background: #fff;
  display: flex;
  padding: 8px;
  animation: searchbar 0.5s;
}

@keyframes searchbar {
   from {
        width: 50px;
   }
   to {
       width: 300px;
   }
}

@media (max-width: 460px)
{
   .height-chats {
   height: 90vh;
}
}
</style>
<template>
    <v-menu
           v-model="phoneInput"
            bottom
            left
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
              >
                <img :src="phone" alt="">
                <span class="icon-text-phone">Novo Atendimento</span>
              </v-btn>
            </template>
    <v-container class="pa-2 container-input">
    <v-container class="d-flex flex-row justify-space-between">
            <p class='ml-4 mt-2 grey--text text--darken-3'>Iniciar Atendimento</p>
            <v-btn
              icon
              @click="phoneInput = false"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
    <v-container class="d-flex flex-row">
    <v-text-field
        v-model="numberinput"
        label="Insira o número: (00) 0 0000 0000"
        rounded
        dense
        outlined
      ></v-text-field>
          <v-btn
           height="40"
           rounded
           outlined
           elevation="0" 
           color="#1976D2"
           class="ml-2 text-capitalize"
           @click="initNewChat(); phoneInput = false; "
          >
          Iniciar
      </v-btn>
      </v-container>
  </v-container>
</v-menu>
</template>
<script>

import phone from '../assets/phone.svg'
import api from '../services/api';
import { mapActions } from 'vuex';

export default {
     name: "NewChatInput",
     data(){
         return {
             phoneInput: false,
             numberinput: "",
             phone: phone
         }
     },
     methods: {
       ...mapActions({
            showAlertMessage: "showAlertMessage"
        }),
        async initNewChat(){
             try {
                await api.post('/newChat', {
                  "number": this.numberinput
                })
             } catch (error) {
                 if(error.response){
                       this.showAlertMessage({message: error.response.data.message, type: "info"});
                    }
             }
        }
     }
}
</script>
<style>
.container-input{
    background-color: #fff;
    width: 450px
}
.icon-text-phone {
  font: normal normal normal 11px/12px Roboto;
  letter-spacing: 0px;
  color: #262A2E;
  opacity: 1;
  text-transform: capitalize;
  margin-top: 5px;
}
</style>
<template>
    <v-col
        md="auto"
        :class="['d-flex', alignClass]"
        >
    <v-dialog
      v-model="dialog"
    >
   <div class="image-container">
    <v-btn
        class="close-button"
        elevation="2"
        large
        icon
        @click="dialog = false"
    >
        <v-icon>mdi-close</v-icon>
     </v-btn>
     <img :src="messageSrc" class="image-dialog">
   </div>
    </v-dialog>
            <v-card
                class="pa-0 rounded-lg pb-4"
                elevation="1"
                dark
                :color="messageColor(data.message.Body.Info.FromMe)"
                @click="dialog = true"
            >
            <v-container>
                <img
                    :src="messageSrc"
                    class="image"
                />
            </v-container>
            <p v-if="data.message.Body.Caption" class="py-2 px-4 text-message" v-html="formatMessage(data.message.Body.Caption)"></p>
            <MessageCardInfo :data="data" />
        </v-card>
</v-col>
</template>
<script>
import MessageCardInfo from './MessageCardInfo.vue';
export default {
    name: "FileMessage",
    props: {
         data: Object,
    },
    components: {
        MessageCardInfo
    },
    data(){
        return {
              dialog: false,
              messageSrc: this.data.message.Url ? this.data.message.Url : this.data.message.Body.Url
        }
    },
    methods: {
    formatMessage(message){
       const value = message.replace(/"/g,"%%").replace(/\*/g,'"');

        let value2 = value.match(/"(.*?[^\\])"/);

        if(value2 !== null){
            let result = value.replace(/\n/g,'<br>');

            value2 = result.match(/"(.*?[^\\])"/);

            while(value2 !== null){

                result = value2[1].includes("<br>") ? 
                result.replace(/".*?"/,`*${value2[1]}*`) :
                result.replace(/".*?"/,`<b>${value2[1]}</b>`);
            
                value2 = result.match(/"(.*?[^\\])"/);
            }
            return result.replace(/%%/g,'"');
        }
        return value.replace(/\n/g,'<br>').replace(/%%/g,'"');
    },
          messageColor(value){
           return value ? '#C6E4F8' : '#FFFFFF'
       },
        formatDate(dateString){
           const date = new Date(dateString);

           const hours = date.getHours();
           const minutes = date.getMinutes();

           const finalMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
         
           return `${hours}:${finalMinutes}`
       }
    },
    computed: {
        alignClass() {
            return this.data.message.Body.Info.FromMe ? 'justify-end' : 'justify-start';
        }
    }
}
</script>
<style scoped>
.close-button {
    position: absolute !important;
    top: 0px;
    right: 20px;
}
.text-message {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0px;
    color: #58606B;
    opacity: 1;
}
.image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.image {
    object-fit: cover;
    height: 250px;
    max-width: 250px;
}
.image-dialog {
    object-fit: cover;
    max-height: 80vh;
    max-width: 80vw;
}
</style>
<template>
    <v-col
       md='auto'
       :class="['d-flex', alignClass]"
        >
            <v-card
                class="rounded-lg pb-4"
                elevation="1"
                dark
                :color="messageColor"
            >
            <v-card-title class="pa-4 black--text">
            <audio controls >
                <source :src="audiosource" type="audio/ogg">
                Your browser does not support the audio element.
            </audio>
            </v-card-title>
            <MessageCardInfo :data="data" />
        </v-card>
</v-col>
</template>
<script>
import MessageCardInfo from './MessageCardInfo';
export default {
    name: "FileMessage",
    props: {
         data: Object,
    },
    components: {
        MessageCardInfo
    },
    data(){
        return {
             audiosource: this.data.message.Url ? this.data.message.Url : this.data.message.Body.Url,
             messageColor: this.data.message.Body.Info.FromMe ? '#C6E4F8' : '#FFFFFF'
        }
    },
    computed: {
        alignClass() {
            return this.data.message.Body.Info.FromMe ? 'justify-end' : 'justify-start';
        }
    }
}
</script>
<style >

audio::-webkit-media-controls-play-button,
    audio::-webkit-media-controls-panel {
     background-color: #E3F2FD;
     color: #E3F2FD;
}

</style>
<template>
     <v-container>
             <h1>Config Page</h1>
             <v-btn @click="goBack">Voltar</v-btn>
     </v-container>
</template>
<script>
export default {
    name: "ConfigPage",
    methods: {
        goBack(){
            this.$router.go(-1);
        }
    }
}
</script>
<style scoped>
     
</style>

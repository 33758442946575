const DaysOfWeekPT = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado"
]

const DaysOfWeekEN = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
]

export default function formatWeekDay(dateString,language){
    const newDate = new Date(dateString);

    const weekDay = newDate.getDay();

     if(language === "PT"){

         return DaysOfWeekPT[weekDay];
     }
     return DaysOfWeekEN[weekDay];
}
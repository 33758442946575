<template>
<v-container
  class="keyboard-class"
>
    <div class="input-container">
    <v-btn 
      text 
      fab 
      @click="getFile"
      color="blue" 
      max-height="50"
      max-width="50"
      class="mt-n2"
    >
    <v-icon>mdi-paperclip</v-icon>
    </v-btn>
    <input type="file" id="file" >
        <v-textarea
            label="Mensagem:"
            hide-details="auto"
            v-model="message"
            id="text-input"
            rounded
            dense
            outlined
            height="48"
            no-resize
            @keyup.enter.exact="sendNewMessage"
        ></v-textarea>
    <div class="audio-input">
        <v-btn
          fab
          small
          @click="closeRecord"
        >
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <div id="record-icon"></div>
        <v-btn
          fab
          small
          @click="sendAudioMessage"
        >
            <v-icon>mdi-check</v-icon>
        </v-btn>
    </div>
    <v-btn
      v-if='isMessage'
      class="ml-md-2 mt-n2"
      text
      fab
      max-height="50"
      max-width="50"
      @click="sendNewMessage"
      color="#1976D2"
    >
      <v-icon size="30">mdi-send</v-icon>
    </v-btn>
    <v-btn
        v-else
        text
        class="ml-md-2 mt-n2"
        @click="startRecord"
        fab
        max-height="50"
        max-width="50"
        color="#1976D2"
        >
        <v-icon size="30">mdi-microphone</v-icon>
    </v-btn>
    </div>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import formatExtension from '../utils/formatExtension';

export default {
     name: "SendMessage",
     data(){
         return {
             message: '',
             chatNumber: this.currentChat,
             mediaRecorder: null,
             audiodata: null,
             base64Audio: null,
             base64File: null,
             fileName: ""
        }
     },
     methods: {
        ...mapActions({
            sendMessage: 'message/sendMessage',
            sendFileMessage: 'message/sendFileMessage',
            showAlertMessage: 'showAlertMessage'
        }),
        formatExtension,
        getFile(){
             document.querySelector('#file').click();

             document.querySelector('#file').addEventListener("change", this.handleFile);
        },
        async handleFile(e){
            const extensions = ['pdf','doc','docx','txt','csv','xls','xlsx','jpg','png','jpeg','jfif','jpe'];
            if(e.target.files[0] && extensions.includes(this.formatExtension(e.target.files[0].name))){
                this.fileName = e.target.files[0].name
                this.base64File = await this.convertToBase64(e.target.files[0]);

                //console.log(e.target.files[0]);

                 const fileMessage = {
                     "message_type": "file",
                     "number": this.chatNumber,
                     "file_name": this.fileName,
                     "file": this.base64File
                }

                await this.sendFileMessage(fileMessage);
            }
        }, 
        convertToBase64(file){
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        sendNewMessage (){
           if(this.message.trim() !== "" && this.message.length > 0){
   
            this.sendMessage(this.message);

            this.message = "";  
    
        }
       },
         sendAudioMessage(){

            this.closeRecord()
            
            setTimeout( async () => {

            //console.log("Base64 Audio", this.base64Audio)

            const newAudioMessage = {
                     "message_type": "file",
                     "number": this.chatNumber,
                     "file_name": "audio.ogg",
                     "file": this.base64Audio
                }

            await this.sendFileMessage(newAudioMessage);

            },1000);
        
       },
       startRecord(){
           if(navigator.mediaDevices === undefined){
            navigator.mediaDevices = {};
            navigator.mediaDevices.getUserMedia = function(constraintObj) {
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, constraintObj, resolve, reject);
                });
            }
           }else {
            navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device=>{
                    console.log(device.kind.toUpperCase(), device.label);
                    // console.log(device.deviceId);
                })
            })
            .catch(err=>{
                console.log(err.name, err.message);
            })
           }

         let constraintObj = { 
            audio: true,
            video: false 
          };
        //let finalaudio = document.querySelector("#finalaudio");

        navigator.mediaDevices.getUserMedia(constraintObj)
        .then((mediaStreamObj) => {
            document.querySelector(".audio-input").style.visibility = 'visible';
            //connect the media stream to the first video element
            this.mediaRecorder = new MediaRecorder(mediaStreamObj);

            this.audioChunk = [];

            this.mediaRecorder.ondataavailable = (ev) => {
                this.audioChunk.push(ev.data);
            }

            this.mediaRecorder.onstop = async () =>{
                let blob = new Blob(this.audioChunk, { 'type' : 'audio/ogg;' });

                this.base64Audio = await this.convertToBase64(blob);

                this.audioChunk = [];

               /* let audioUrl = window.URL.createObjectURL(blob);
                finalaudio.src = audioUrl;
                finalaudio.style.visibility = 'visible'

                console.log("Audio Url", audioUrl); */

                mediaStreamObj.getTracks() // get all tracks from the MediaStream
                .forEach( track => track.stop() 
              ); 
            }

            this.mediaRecorder.start();

            // console.log(this.mediaRecorder.state);

        }).catch(error => {
             console.log(error);
             this.showAlertMessage({ message: 'Libere o seu microfone no navegador. clicando no cadeado acima', type: "info" });
        });
       },
       closeRecord(){

            this.mediaRecorder.stop();

            // console.log(this.mediaRecorder.state);
            
            document.querySelector(".audio-input").style.visibility = 'hidden';
    
       }
     },
     computed: {
        ...mapGetters({
            currentChat: 'chat/currentChat'
        }),
        isMessage(){
            return this.message.length > 0 ? true : false;
        },
     },
     mounted(){
         this.chatNumber = this.currentChat;
     },
     watch: {
         currentChat(val){
             //console.log("numero alterado");
             this.chatNumber = val;
         },
     },
 }
</script>
<style scoped>

.input-container {
  display: flex;
  position: relative;
}

.audio-input {
    width: 200px;
    height: 50px;
    background-color: lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;
    position: absolute;
    right: 60px;
}
#record-icon {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    animation: recordicon 1.2s infinite ease-in;
}
@keyframes recordicon {
    from {
        opacity: 0.0;
     }
    to {
        opacity: 1;
    }
}
#finalaudio {
    visibility: hidden;
    position: absolute
}

input[type="file"]{
    display: none;
}
</style>
import * as methods from './methods';
import * as requests from './requests';

export default {
    namespaced: true,
    state: {
        chats: [],
        currentChat: null,
        currentChatUser: null,
        newChatData: {},
        closedChats: null,
        historyChats: null,
        chatsByDates: null
    },
    getters: {
        chats(state){
            return state.chats;
        },
        currentChat(state){
            return state.currentChat;
        },
        currentChatUser(state){
            return state.currentChatUser;
        },
        newChatData(state){
            return state.newChatData;
        },
        closedChats(state){
            return state.closedChats;
        },
        historyChats(state){
            return state.historyChats;
        },
        chatsByDates(state){
            return state.chatsByDates;
        }
    },
    mutations: {
        SET_CHATS(state, chats){
            state.chats = chats;
        },
        SET_CURRENT_CHAT(state, val){
            state.currentChat = val;
        },
        SET_CURRENT_CHAT_USER(state, val){
            state.currentChatUser = val;
        },
        SET_NEW_CHAT_DATA(state, val){
            state.newChatData = val;
        },
        SET_CLOSED_CHATS(state, val){
            state.closedChats = val;
        },
        SET_HISTORY_CHATS(state, val){
            state.historyChats = val;
        },
        SET_CHATS_BY_DATES(state, val){
            state.chatsByDates = val
        }
    },
    actions: {
         ...methods,
         ...requests
    }
}
<template>
    <v-btn
        width="100%" 
        outlined 
        elevation="0" 
        color="#1976D2"
        class="text-capitalize"
        :loading="loading"
        :disabled="loading"
        @click="loader = 'loading'"
        >
          {{title}}
    </v-btn>
</template>
<script>
export default {
    name: "CustomButtonLoad",
    props: {
        title: String,
        customFunction: Function
    },
    data(){
        return {
            loader: null,
            loading: false,
        }
    },
    watch: {
        async loader (val) {
         if(val){
             const l = this.loader
             this[l] = !this[l]
             
             await this.customFunction();

              this[l] = false;
              this.loader = null
         }
        }
    }
}
</script>

export default {
    namespaced: true,
    state: {
        showUserProfile: false,
        showSearchMessages: false,
        showChatConfig: false,
        showChatsInfo: false,
        showRightFromMenuTop: false,
        showMessagesData: false
    },
    getters: {
        showUserProfile(state){
            return state.showUserProfile;
        },
        showSearchMessages(state){
            return state.showSearchMessages;
        },
        showChatConfig(state){
            return state.showChatConfig;
        },
        showChatsInfo(state){
            return state.showChatsInfo;
        },
        showRightFromMenuTop(state){
            return state.showRightFromMenuTop;
        },
        showMessagesData(state){
            return state.showMessagesData;
        }
    },
    mutations: {
        SET_SHOW_RIGHT_CONTENT(state, value){ 

            switch(value){
                case "Profile": 
                    state.showUserProfile = !state.showUserProfile;
                    state.showSearchMessages = false;
                    state.showChatConfig = false;
                    state.showChatsInfo = false;
                    state.showMessagesData = false;
                break;
                case "SearchMessages":
                    state.showUserProfile = false;
                    state.showSearchMessages = !state.showSearchMessages;
                    state.showChatConfig = false;
                    state.showChatsInfo =false;
                    state.showMessagesData = false;
                break;
                case "ChatConfig":
                    state.showUserProfile = false;
                    state.showSearchMessages = false;
                    state.showChatConfig = !state.showChatConfig;
                    state.showChatsInfo = false;
                    state.showMessagesData = false;
                break;
                case "ChatsData":
                    state.showUserProfile = false;
                    state.showSearchMessages = false;
                    state.showChatConfig = false;
                    state.showMessagesData = false;
                    state.showRightFromMenuTop = !state.showRightFromMenuTop;
                    state.showChatsInfo = !state.showChatsInfo;
                break;
                case "MessagesData":
                    state.showUserProfile = false;
                    state.showSearchMessages = false;
                    state.showChatConfig = false;
                    state.showChatsInfo = false;
                    state.showRightFromMenuTop = !state.showRightFromMenuTop;
                    state.showMessagesData = !state.showMessagesData;
                break;
            }
            // console.log(state.showMessagesData);
        },
        SET_CLOSE_RIGHT_CONTENT(state){
            state.showUserProfile = false;
            state.showSearchMessages = false;
            state.showChatConfig = false;
            state.showChatsInfo = false;
            state.showMessagesData = false;
            state.showRightFromMenuTop = false;
        },
    },
    actions: {
        showRightContent({ commit } , name){
            commit('SET_SHOW_RIGHT_CONTENT', name);
        },
        closeRightContent({ commit }){
            commit('SET_CLOSE_RIGHT_CONTENT');
        },

    },
}
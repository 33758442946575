<template>
        <v-dialog
          v-model="dialog"
          width="500"
        >
  <div class="text-center">
      <v-card>
        <v-list two-line>
        <v-list-item-group
           v-model="selectedItem"
           color="primary"
         >
          <template v-for="(item, index) in clientsData">
            <v-subheader
              v-if="item.header"
              :key="item.header"
            >
              {{ item.header }}
            </v-subheader>
            <v-divider
              v-else-if="item.divider"
              :key="index * 100"
              :inset="item.inset"
            ></v-divider>
            <v-list-item
              v-else
              :key="index"
               @click="
                setSelectedClient(item)
               "
            >
              <v-list-item-content>
                <v-list-item-title v-html="item.client_name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        </v-list>
       <v-card-actions v-if="selectedClient">
           <v-spacer></v-spacer>
          <p>Alterar para <strong>{{ selectedClient.client_name }}</strong> ?</p>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
            selectedClient = null;
            "
          >
            Não
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              signInClient(selectedClient);
              selectedClient = null;
            "
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
  </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "DialogChangeClient",
    data(){
        return {
            dialog: false,
            clients: [],
            selectedItem: 0,
            selectedClient: null
        }
    },
    computed: {
        ...mapGetters({
           dialogChangeClient: 'auth/dialogChangeClient'
        }),
        clientsData(){
            let data = [];
            data.push({ header: 'Clientes' });
            this.clients.map(item => {
                data.push(item);
                data.push({ divider: true, inset: true });
            })
            return data;
        },
    },
    methods: {
        ...mapActions({
            signInClient: 'auth/signInClient'
        }),
        setSelectedClient(item){
            this.selectedClient = item;
        }
    },
    watch: {
      dialogChangeClient(val){
          this.dialog = val.show;
          this.clients = val.clients;
      }
    }
}
</script>
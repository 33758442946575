<template>
    <v-container>
        <v-dialog
          v-model="dialog"
          width="500"
        >
        <DialogTransferChat @showDialog='showDialog'/>
        </v-dialog>
        <CloseChatDialog
          @onConfirm="closeChat(true); dialogCloseChat = false"
          @onClose="closeChat(false); dialogCloseChat = false"
          @onCancel="dialogCloseChat = false"
          :dialog="dialogCloseChat"
          message="Deseja enviar mensagem de encerramento?"
          type="warning"
        />
        <v-row class="justify-space-between flex-wrap">
            <v-col
                  md="4"
                >
            <v-card
              elevation="0"
              v-if="currentChatUser"
            >
              <v-list-item>
                <v-list-item-avatar size="48">
                  <v-img :src="imageSrc"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <!-- eslint-disable vue/no-v-html, vue/no-v-text-v-html-on-component -->
                  <v-list-item-title v-html="profileName"></v-list-item-title>
                  <v-list-item-subtitle class="blue--text cursor-click" @click="showRightContent('Profile')">
                        Perfil 
                        <v-icon size="18" color='blue' >mdi-pencil</v-icon>
                       <span v-if="showisTyping" class="grey--text font-italic">| Digitando...</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            </v-card>
            </v-col>
            <v-col
                sm="4"
                md="4"
                class="action-buttons"
            >
              <v-btn
                  color="blue"
                  v-if='showActionButtons'
                  @click="dialog = true"
                  text
                  fab
                >
                <div>
                  <v-icon>mdi-account-switch</v-icon> 
                   <p class="icons-text">Transferir</p>
                </div>
                </v-btn>
                <v-btn
                  color="blue"
                  v-if='showActionButtons'
                  @click="dialogCloseChat = true" 
                  text
                  fab
                >
                  <div>
                    <v-icon>mdi-chat-remove</v-icon>
                    <p class="icons-text">Encerrar</p>
                  </div>
                </v-btn>
                <v-btn
                    color="blue"
                    text
                    @click="showRightContent('SearchMessages')"
                    fab
                  >
                   <div>
                      <v-icon>mdi-magnify</v-icon>
                      <p class="icons-text">Buscar</p>
                   </div>
                </v-btn>
                   <v-btn
                    color="blue"
                    text
                    @click="showRightContent('ChatConfig')"
                    fab
                  >
                    <div>
                      <v-icon>mdi-file-edit</v-icon>
                      <p class="icons-text">Lembrete</p>
                    </div>
                </v-btn>
                <v-btn
                    color="blue"
                    text
                    fab
                    @click="loadChatsByNumber"
                  >
                    <div>
                      <v-icon>mdi-history</v-icon>
                      <p class="icons-text">Historico</p>
                    </div>
                </v-btn>
            </v-col>
            <v-col
              class="menu-drop-down"
            >
            <v-menu offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    class="mr-n4"
                  >
                   <v-icon>
                     mdi-dots-vertical
                   </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="pb-0">
                  <v-list-item 
                      v-if='showActionButtons' 
                      @click="dialog = true" 
                  >  
                     <v-list-item-icon class="ma-0">
                      <v-icon size="18" color="blue">mdi-account-switch</v-icon> 
                   </v-list-item-icon>
                      <p class="icons-text">Transferir</p>
                  </v-list-item>
                  <v-list-item 
                      v-if='showActionButtons' 
                      @click="dialogCloseChat = true" 
                  >
                     <v-list-item-icon class="ma-0">
                        <v-icon size="18" color="blue">mdi-chat-remove</v-icon>
                     </v-list-item-icon>
                        <p class="icons-text">Encerrar</p>
                  </v-list-item>
                  <v-list-item @click="showRightContent('SearchMessages')">
                      <v-list-item-icon class="ma-0">
                          <v-icon size="18" color="blue">mdi-magnify</v-icon>
                      </v-list-item-icon>
                        <p class="icons-text">Buscar</p>
                  </v-list-item >
                  <v-list-item @click="showRightContent('ChatConfig')" v-if='showActionButtons' >
                    <v-list-item-icon class="ma-0">
                       <v-icon size="18" color="blue" >mdi-file-edit</v-icon>
                    </v-list-item-icon>
                     <v-list-item-content class="pb-0">
                       <p class="icons-text">Lembrete</p>
                     </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="loadChatsByNumber">
                    <v-list-item-icon class="ma-0">
                       <v-icon size="18" color="blue">mdi-history</v-icon>
                    </v-list-item-icon>
                     <v-list-item-content class="pb-0">
                       <p class="icons-text">Histórico</p>
                     </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-menu>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import DialogTransferChat from "./dialogs/DialogTransferChat.vue";
import default_user from '../assets/default_user.jpg';
import CloseChatDialog from './dialogs/CloseChatDialog.vue';

export default {
    name: "TopMessageNav",
    components: {
       DialogTransferChat,
       CloseChatDialog
    },
    data(){
         return {
            dialogCloseChat: false,
            dialog: false,
            windowWidth: window.innerWidth
         }
    },
    computed: {
      ...mapGetters({
         currentChatUser: 'chat/currentChatUser',
         user: 'auth/user',
         isTypingMessage: 'message/isTypingMessage'
      }),
      profileName(){

        const profile = this.currentChatUser.profile;

        return profile.name ? (profile.name.length > 12 && this.windowWidth <= 320 ) ? `${profile.name.substring(0, 12)}...` : profile.name
               : profile.number
      },
      showActionButtons(){
        return this.currentChatUser && this.currentChatUser.user && this.user.id == this.currentChatUser.user.id && this.currentChatUser.status !== "E";
      },
      imageSrc(){
        return this.currentChatUser.profile.url ? this.currentChatUser.profile.url : default_user;
      },
      showisTyping(){
        return this.currentChatUser.profile.number && this.isTypingMessage[this.currentChatUser.profile.number];
      }
    },
    methods: {
      ...mapActions({
        showRightContent: 'rightcontent/showRightContent',
        closeChat: 'chat/closeChat',
        loadChatsByNumber: 'chat/loadChatsByNumber'
      }),
      showDialog(value){
        this.dialog = value;
      },
      handleResize(){
          this.windowWidth = window.innerWidth;
      }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>
<style>
.icons-text {
  font: normal normal normal 11px/12px Roboto;
  letter-spacing: 0px;
  color: #262A2E;
  opacity: 1;
  text-transform: capitalize;
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu-drop-down {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cursor-click {
  cursor: pointer;
}

@media (max-width: 460px)
{
  .action-buttons
   {
    display: none !important;
    flex:none;
   }
}

@media (min-width: 460px){
  .menu-drop-down {
      display: none
  }
}
</style>
import SockJS from "sockjs-client"
import { Client } from "@stomp/stompjs"
import api from '../services/api';

export default {
    namespaced: true,
    state: {
        socketClient: null,
        socketSubscription: null,
        qrCode: null,
        qrCodeAlert: false,
        phoneConnection: false
    },
    getters: {
        socketClient(state){
           return state.socketClient;
        },
        socketSubscription(state){
            return state.socketSubscription;
         },
        qrCode(state){
            return state.qrCode;
        },
        qrCodeAlert(state){
            return state.qrCodeAlert;
        },
        phoneConnection(state){
            return state.phoneConnection;
        }
    },
    mutations: {
        SET_SOCKET_CLIENT(state, newsocketClient){
            state.socketClient = newsocketClient;
        },
        SET_SOCKET_SUBSCRIPTION(state, newSocketSubscription){
            state.socketSubscription = newSocketSubscription;
        },
        SET_QRCODE(state, value){
            state.qrCode = value;
        },
        SET_QRCODE_ALERT(state, value){
            state.qrCodeAlert = value;
        },
        SET_PHONE_CONNECTION(state, value){
            state.phoneConnection = value;
        }
    },
    actions: {
        startSocket({ rootGetters, commit, dispatch, getters }){
            const user = rootGetters['auth/user'];
            const token = rootGetters['auth/token'];
            const url = "https://chat.novaxs.com.br:8191/chat-websocket";

            this.onReceiveMessage = (message) => {
                const obj = JSON.parse(message.body);
                if(obj.type == "chat_message" || obj.type == 'message_send' || obj.type == 'message_read'){
                    dispatch('message/pushMessage', obj.message, { root: true });
                }else if(obj.type == 'chat'){
                    dispatch('chat/pushChat', obj.message, { root: true });
                }else if(obj.type == "typing"){
                    dispatch('message/showTyping', obj.message, { root: true });
                }else if(obj.type == "connection_status", obj.message){
                    if(obj.message.status === "disconnected"){
                        commit('SET_QRCODE_ALERT', true);
                    }
                    if(obj.message.status === "connected" && getters.qrCodeAlert){
                        dispatch('closeQrCodeAlert');
                        dispatch("showAlertMessage" , {message: "Celular Conectado", type: "success" } , { root: true });
                    }
                }
            }

            this.client = new Client();
           
            this.client.configure( {
                brokerURL: url,
                connectHeaders:  {
                    "Authorization" : token
                },
                webSocketFactory: () => {
                    return new SockJS(url);
                },
                onConnect: () => {
                    // console.log("conectou");
                    this.subscription = this.client.subscribe("/chat/notifications/" + user.selectedClient.client_id, this.onReceiveMessage);
                    commit('SET_SOCKET_SUBSCRIPTION', this.subscription);
                },
                onDisconnect(){
                    const subscription = getters.socketSubscription;
                    // console.log("subscription", subscription);
                    subscription.unsubscribe();
                    // console.log("Socket Desconectado");
                },
                onerror
            });   
           
            this.client.activate();       
            commit('SET_SOCKET_CLIENT', this.client);           
        },
        async closeSocket({ getters }){

            // console.log("Is Calling close Websocket");
           
            this.client = getters.socketClient;

            if(this.client){

                await this.client.deactivate();
            }
        },
        async generateQrCode({ commit, dispatch }){
             try {
                const response = await api.get('/qrCode');

                commit('SET_QRCODE', response.data.qr_code);

             } catch (error) {
                 if(error.response){
                     dispatch('showErrorAlert', error.response.data.message, { root: true });
                 }else {
                    dispatch('showErrorAlert', "Ocorreu um erro ao gerar QrCode", { root: true });
                 }
             }
        },
        closeQrCodeAlert({ commit }){
            commit('SET_QRCODE_ALERT', false);
        },
        async checkConnectionStatus({ dispatch, commit }){
               commit('SET_PHONE_CONNECTION', false);
               try {
                await api.get('/status');

                commit('SET_PHONE_CONNECTION', true);

                dispatch('closeQrCodeAlert');

               } catch (error) {
                   console.log(error);
                   commit('SET_QRCODE_ALERT', true);
               }
        }
    }
}
export default function checkFollowUpExpired(item){
    const today = new Date();

    const dateFollowUp = item.follow_up ? new Date(`${item.follow_up}T00:00:00`) : null;

    const lasMessageDate = item.last_message ? new Date(item.last_message.date): null;

     if(lasMessageDate){
        lasMessageDate.setHours(0,0,0,0);
     }
    today.setHours(0,0,0,0);

     return dateFollowUp !== null && lasMessageDate !== null && dateFollowUp > lasMessageDate
     && dateFollowUp < today;
}
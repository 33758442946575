export default function formatDate(dataObj,separator,reverse){
    if(dataObj instanceof Date){
        const day = dataObj.getDate() , month = dataObj.getMonth(), year = dataObj.getFullYear();
        const dayMonth = day < 10 ? `0${day}` : day;
        const fMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
        
        if(reverse){
            return `${year}${separator}${fMonth}${separator}${dayMonth}`;
        }
        return `${dayMonth}${separator}${fMonth}${separator}${year}`;
    }else{
        const date = new Date(dataObj);

        const day = date.getDate() , month = date.getMonth(), year = date.getFullYear();
        const dayMonth = day < 10 ? `0${day}` : day;
        const fMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
        
        if(reverse){
            return `${year}${separator}${fMonth}${separator}${dayMonth}`;
        }
        return `${dayMonth}${separator}${fMonth}${separator}${year}`;
    }
    
  }



 
import { checkMessageWithError, updateMessageWithError } from './utils/messageWithError';
import updateMessagesArray from './utils/updateMessages';
import formatMessageNumber from './utils/formatMessageNumber';

export function pushMessage({ getters, commit, rootGetters, dispatch }, message){
          
        const messages = getters.currentMessages;
        const currentChat = rootGetters['chat/currentChat'];
        const currentChatUser = rootGetters['chat/currentChatUser'];
        
         if(message.message.Body.Info.FromMe){
            
            const currentMessage = messages.find(item => item.id === message.id);

            const messagesWithError = checkMessageWithError(messages,message.message.Body.Text);

                if(!currentMessage && !messagesWithError){
                     
                    if(currentChatUser.id === message.chat.id){
                        
                        messages.push(message);

                        commit('SET_MESSAGES', messages);

                        dispatch('updateChatLastMessage', message);
                    }
                }else {
                        if(messagesWithError){
                           
                            const updatedMessage = {...message, messageWithError: false}

                            const messagesUpdated = updateMessageWithError(messages,updatedMessage);

                            commit('SET_MESSAGES',messagesUpdated);

                        }else {
                            const updatedMessage = {...currentMessage, send: message.send}

                            const messagesUpdated = updateMessagesArray(messages,updatedMessage);

                            commit('SET_MESSAGES',messagesUpdated);
                        }
                }
         }else{
 
            if(currentChat === message.chat.profile.number){

                messages.push(message);

                commit('SET_MESSAGES', messages);

                dispatch('removeTypingMessage',message.chat.profile.number);

            }else {
                const newMessages = getters.newMessages;

                if(newMessages[message.chat.id]){
                    
                    newMessages[message.chat.id].push(message);

                    dispatch('removeTypingMessage',message.chat.profile.number);
                }else {
                    
                    newMessages[message.chat.id] = [];

                    newMessages[message.chat.id].push(message);

                    dispatch('removeTypingMessage',message.chat.profile.number);
                }
                commit('SET_NEW_MESSAGES', {...newMessages});
                dispatch('updateChatLastMessage', message);
            }
         }
         
    }
export function updateChatLastMessage({ dispatch, rootGetters }, message){
    const chats = rootGetters['chat/chats'];

    const chatMessage = chats.find(item => item.id === message.chat.id)
                        
        const updatedChat = {
            ...chatMessage,
            last_message: message,
            last_message_from_client: message.chat.last_message_from_client
        }
        //console.log("Atualizando Chat");
        dispatch('chat/pushChat', updatedChat , { root: true });
}

export function showTyping({ getters, commit, dispatch }, message){

        const isTypingMessage = getters.isTypingMessage;

        const formatedMessageNumber = formatMessageNumber(message[1].id);

        isTypingMessage[formatedMessageNumber] = true;

        commit("SET_ISTYPING_MESSAGE", {...isTypingMessage});

        setTimeout(() => {
            dispatch('removeTypingMessage',formatedMessageNumber);
        },1000 * 15);
    }
export function removeTypingMessage({ getters, commit }, number){

    const isTypingMessage = getters.isTypingMessage;

    if(isTypingMessage[number]){
        isTypingMessage[number] = false;

        commit("SET_ISTYPING_MESSAGE", {...isTypingMessage});
    }
}

<template>
    <v-container class="pa-0">
        <v-container class="d-flex flex-row justify-space-between">
            <p class='ml-4 mt-2 grey--text text--darken-3'>Buscar</p>
            <v-btn
              icon
              @click="closeRightContent"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
        <v-container>
            <v-text-field
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            label="Buscar mensagem"
            rounded
            dense
            outlined
            clearable
            v-model="message"
            @keyup="findMessages"
        ></v-text-field>
        </v-container>
       <v-container class="search-message-container">
        <v-virtual-scroll
           :items="searchMessages"
           item-height='90'
           class='overflow-x-hidden'
        >
            <template v-slot:default="{ item }">
                <SearchTextMessage
                     :key="item.id"
                      :data='item'
                      @scrollToMessage="$emit('scrollToMessage', item.id)"
                />
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>
        </v-container>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SearchTextMessage from './SearchTextMessage.vue';

export default {
    name: "SearchMessage",
    components: {
        SearchTextMessage
    },
    data() {
        return {
            message: '',
            showSearch: false,
            searchMessages: []
        }
    },
    methods: {
        ...mapActions({
            closeRightContent: 'rightcontent/closeRightContent'
        }),
        findMessages(){
            this.searchMessages = [];

            if(this.message !== '', this.message.length > 0){
                this.searchMessages = this.currentMessages.filter(item => {
                return item.message.Body.Text && 
                       item.message.Body.Text.toLowerCase().includes(this.message.toLowerCase());
               })
            }
        }
    },
    computed: {
        ...mapGetters({
            currentMessages: 'message/currentMessages',
        })
    },
    watch: {
        showSearchMessage(){
            this.showSearch = !this.showSearch;
        },
        message(val){
           if(val === null || val.length <= 0){
               this.searchMessages = [];
           }
        }
    }
}
</script>
<style>
.search-message-container {
    height: 72vh;
}
</style>

<template>
    <div class="form-container">
    <CustomAlert />
    <DialogChangeClient />
    <v-container
          class="form-container center"
          v-if="isLoading"
        >
        <v-progress-circular
            :size="60"
            indeterminate
            color="primary"
         ></v-progress-circular>
    </v-container>
       <v-form
            v-else 
            v-model="valid"  
            @submit.prevent="submit" 
            ref="form" 
            lazy-validation
    >
           <v-text-field
            block
            v-model="login"
            label="Login"
            class="input-field"
            required
            filled
            prepend-inner-icon="mdi-account"
            clearable
            :rules='loginRules'
          ></v-text-field>
         <v-text-field
            block
            filled
            v-model="password"
            label="Senha"
            required
            class="input-field"
            prepend-inner-icon="mdi-lock"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off' "
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules='passwordRules'
          ></v-text-field>
          <v-btn
           block
           color="primary"
           x-large
           type="submit"
          >
            Entrar
        </v-btn>
       </v-form>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import CustomAlert from '../components/dialogs/CustomAlert.vue';
import DialogChangeClient from '../components/dialogs/DialogChangeClient.vue';
export default {
    name: "LoginPage",
    components: {
        CustomAlert,
        DialogChangeClient
    },
    data(){
        return {
          valid: false,
          show1: false,
         
    login: "",
    loginRules: [
        v => !!v || 'login is required',
      ],
    password: "",
     passwordRules: [
        v => !!v || 'Password is required',
      ],
}
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
        }),
        submit(){
            this.validate();
            //console.log(this.valid);
            if(this.valid && this.login != '' && this.password != ""){
                const credencials = {
                       login: this.login, 
                       password: this.password
                   }
                this.signIn(credencials);
            }
  
        },
        validate () {
           this.$refs.form.validate()
      },
    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            isLoading: "auth/isLoading"
        })
    },
    watch: {
        authenticated(){
            if(this.authenticated){
                //console.log("Esta autenticado");
                this.$router.push({ name: "Home"})
            }
        }
    },
    mounted(){
        if(this.authenticated){
            //console.log("Esta autenticado");
            this.$router.push({ name: "Home"})
        }
    },
}
</script>
<style>

.form-container {
    width: 500px;
    margin: auto;
    margin-top: 100px;
}
.input-field {
    font-size: 20px;
}
.button {
    font-size: 42px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 460px){
    .form-container {
        width: 100%;
    }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',{class:[_vm.chatColor(_vm.item.id),_vm.followUpColor(_vm.item),_vm.closedChat(_vm.item.status),_vm.clienteLastMessage(_vm.item), "justify-center"],on:{"click":function($event){_vm.switchChat({ profile: _vm.item.profile, 
                        user: _vm.item.user, 
                        id: _vm.item.id, 
                        status: _vm.item.status, 
                        short_text: _vm.item.short_text, 
                        follow_up: _vm.item.follow_up
                      }); 
           _vm.$emit('closeLeftContent')}}},[_c('v-list-item-avatar',{staticClass:"overflow-visible",attrs:{"size":"56"}},[_c('v-img',{attrs:{"src":_vm.item.profile.url ? _vm.item.profile.url : _vm.default_user}}),(_vm.isTypingMessage[_vm.item.profile.number] && _vm.item.status !== "E")?_c('div',{staticClass:"badge-typing d-none d-sm-flex d-md-none"},[_c('v-icon',{attrs:{"color":"#fff"}},[_vm._v("mdi-dots-horizontal ")])],1):_vm._e()],1),_c('v-list-item-content',{staticClass:"d-sm-none d-md-flex"},[_c('v-list-item-title',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.item.profile.name ? _vm.item.profile.name : _vm.formatPhoneNumber(_vm.item.profile.number))}}),(_vm.isTypingMessage[_vm.item.profile.number] && _vm.item.status !== "E")?_c('v-list-item-subtitle',{staticClass:"font-italic text-caption"},[_vm._v(" Digitando... ")]):_c('v-list-item-subtitle',{staticClass:"text-caption mb-0 mt-n1"},[_vm._v(" "+_vm._s(_vm.item.status_name)+" ")]),(_vm.item.user)?_c('v-list-item-subtitle',{staticClass:"mt-n2"},[_c('b',[_vm._v("Por:")]),_vm._v(" "),_c('span',{staticClass:"font-italic text-caption"},[_vm._v(_vm._s(_vm.formatUserName(_vm.item.user.user_name)))])]):_vm._e()],1),_c('div',[(_vm.dateFollowUp(_vm.item))?_c('v-card',{staticClass:"pa-1 d-sm-none d-md-flex",attrs:{"height":"28px","elevation":"0"}},[_c('p',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.dateFollowUp(_vm.item)))])]):_vm._e(),(_vm.newMessages[_vm.item.id] && _vm.newMessages[_vm.item.id].length !== 0)?_c('div',{staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.newMessages[_vm.item.id].length)+" ")]):_vm._e(),_c('audio',{staticClass:"d-none",attrs:{"controls":"","id":"notification-player"}},[_c('source',{attrs:{"src":_vm.audioNotification,"type":"audio/mp3"}}),_vm._v(" Your browser does not support the audio element. ")]),_c('span',{staticClass:"font-italic text-caption mt-10"},[_vm._v(_vm._s(_vm.lastMessageHour(_vm.item)))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
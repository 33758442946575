<template>
     <v-container class="pa-0 overflow-x-hidden max-height-container">
        <v-container class="d-flex flex-row justify-space-between">
            <p class='mt-2 grey--text text--darken-3'>Disparo de Mensagens</p>
            <v-btn
              icon
              @click="closeRightContent"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
        <v-container class="pr-4 pb-0">
            <MenuDatePickerRange @onPress="loadStatistic" />
        </v-container>
        <v-container class="pt-0">
        <v-select
          v-model="tagSelect"
          :items="tags"
          label="Tag"
          item-text="name"
          item-value="name"
          return-object
        ></v-select>
        <table class="statistics-table">
           <thead>
               <tr>
                   <th>Status</th>
                   <th>Mensagens</th>
                   <th>%</th>
               </tr>
           </thead>
           <tbody>
               <tr v-for="(item,idx) in messagesData" :key="idx" >
                   <td>{{item.name}}</td>
                   <td>{{item.value}}</td>
                   <td>{{item.porcent.toFixed(1)}} %</td>
               </tr>
           </tbody>
        </table>
        <div class='total-value'>
            Total enviadas: <span>{{messagesTotal}}</span>
        </div>
        </v-container>
        <v-container>
            <p class='ml-4 mt-2 grey--text text--darken-3'>Gráfico:</p>
            <PieChart :loading="loadChar" :asyncChartData="messagesData" />
        </v-container>
     </v-container>
</template>
<script>
import api from '../../services/api';
import { mapActions } from 'vuex';
import MenuDatePickerRange from '../MenuDataPickerRange.vue';
import arrayDatesToObj from '../../utils/arrayDatesToObj';
import PieChart from '../PieChart.vue';

export default {
    name: "ChatsDataInfo",
    data(){
        return {
            loadChar: false,
            messagesTotal: 0,
            messagesData: [],
            tags: [],
            tagSelect: { name: "Todas" },
            dates: null
        }
    },
    components: {
        MenuDatePickerRange,
        PieChart
    },
    methods: {
        ...mapActions({
            closeRightContent: 'rightcontent/closeRightContent',
             showErrorAlert: "showErrorAlert"
        }),
        async loadTags(){
            try {
                const response = await api.get('/tags');
                this.tags.push({ name: "Todas" });
                response.data.map(item => this.tags.push(item));
            } catch (error) {
               if(error.response){
                     this.showErrorAlert(error.response.data.message);
                }else {
                    this.showErrorAlert("Falha ao carregar Tags");
                }
                console.log(error);
            }
        },
        async loadStatistic(dates){
            const selectedTagName = this.tagSelect.name;
            this.loadChar = true;
            try {
                if(Array.isArray(dates) || this.dates !== null){
                    let dateParams = [];
                    if(Array.isArray(dates)){
                       dateParams = arrayDatesToObj(dates);
                       this.dates = arrayDatesToObj(dates);
                    }else {
                        dateParams = this.dates;
                    }

                    let searchParams = selectedTagName !== "Todas" ? {...dateParams, "tags": selectedTagName } : dateParams;

                    const response = await api.post('/statistics', searchParams);

                    this.updateChartData(response.data);
                    this.loadChar = false;
                }else {
                     if(selectedTagName !== "Todas"){
                        const response = await api.post('/statistics',{ "tags": selectedTagName });
                        
                        this.updateChartData(response.data);
                        this.loadChar = false;
                     }else {
                        const response = await api.get('/statistics');

                        this.updateChartData(response.data);
                        this.loadChar = false;
                     }
                }
            } catch (error) {
                if(error.response){
                     this.showErrorAlert(error.response.data.message);
                }else {
                    this.showErrorAlert("Falha ao carregar estatísticas");
                }
                console.log(error);
            }
        },
        updateChartData(data){
             const { chats_unread_message, chats_read_message, chats_unsent_message} = data;
             const total = chats_unread_message + chats_read_message + chats_unsent_message
             this.messagesTotal = total;
             const dataObj = [
                 {
                   name: "Lidas",
                   value: chats_read_message,
                   porcent: chats_read_message !== 0 ? (chats_read_message / total) * 100 : 0
                 },
                 {
                   name: "Não Lidas",
                   value: chats_unread_message,
                   porcent: chats_unread_message !== 0 ? (chats_unread_message / total) * 100: 0
                 },
                 {
                   name: "Inativos",
                   value: chats_unsent_message,
                   porcent: chats_unsent_message !== 0 ? (chats_unsent_message / total) * 100: 0
                 }
                 ]
            this.messagesData = dataObj;
        }
    },
    watch: {
        tagSelect(){
            this.loadStatistic();
        }
    },
    mounted(){
        this.loadStatistic();
        this.loadTags();
    },
}
</script>
<style scoped>
.statistics-table {
    border-collapse: collapse;
    font-size: 14px;
    text-align: justify;
}
.statistics-table tr td, th{
    border-bottom: 1px solid lightgray;
    padding: 5px;
}
.total-value {
    font-family: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 208px;
    height: 30px;
    font-weight: 500;
    padding: 5px;
}
.total-value span{
    margin-left: 30px;
}
@media (max-width:1540px) {
  .statistics-table {
    width: 100%;
}
.total-value {
    width: 100%;
}
.total-value span{
    margin-left: 20px;
}
}
.max-height-container {
    height: 90vh
}
</style>

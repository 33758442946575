export default function formatHours(dateString){
    const date = new Date(dateString);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const finalHours = hours < 10 ? `0${hours}` : `${hours}`

    const finalMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  
    return `${finalHours}:${finalMinutes}`
}
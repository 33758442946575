<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
    <v-alert
      border="left"
      colored-border
      :type="type"
      elevation="2"
    >
      {{message}}

        <div>
          <v-btn
            width="100"
            outlined
           :color="btnColor"
            text
            @click="$emit('onConfirm')"
            class="ml-8"
          >
            Sim
        </v-btn>
          <v-btn
            :color='btnColor'
            width="100"
            @click="$emit('onClose')"
            class="ml-4"
          >
            <span class="white--text">Não</span>
          </v-btn>
        </div>
    </v-alert>
    </v-dialog>
</template>
<script>

export default {
    name: "CloseMessageBox",
    props: {
       type: String,
       dialog: Boolean,
       message: String,
    },
    computed:{
      btnColor() {
        let color = '';
            switch(this.type){
               case "warning": 
                  color = "orange";
                break;
              case "error": 
                  color = 'red';
                break;
              case "info":
                 color = 'blue'
              break;
            default:
                  color = 'blue'
      }
      return color;
    }
  }
}
</script>
<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Selecione o Período"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          range
          no-title
          scrollable
          locale="pt-BR"
          :max="maxDate"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(dates); $emit('onPress', dates)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
</template>

<script>
import formatDate from "../utils/formateDate";
  export default {
    name: "MenuDatePickerRange",
    data: () => ({
      dates: [formatDate(new Date(),"-", true)],
      menu: false,
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
      maxDate(){
          const today = new Date();

          return formatDate(today,"-", true);
        },
    },
  }
</script>
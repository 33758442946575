<template>
    <v-container class="pa-0">
        <v-container class="d-flex flex-row justify-space-between">
            <p class='ml-4 mt-2 grey--text text--darken-3'>Lembrete</p>
            <v-btn
              icon
              @click="closeRightContent"
            >
            <v-icon size="18" color="blue">
                mdi-close
            </v-icon>
        </v-btn>
        </v-container>
        <ChatFollowUp />
        <v-divider></v-divider>
        <v-container>
            <p>Anotações</p>
            <v-textarea
              v-model="shortText"
              outlined
            >
            </v-textarea>
          <CustomButtonLoad
            title="Salvar anotação"
            :customFunction="saveShortText"
          />
        </v-container>
        <v-divider v-if='currentChatUser.short_text'></v-divider>
        <v-container v-if="currentChatUser.follow_up">
          <p>Lembrete</p>
         <div class="message-box followup-card">
            {{formatWeekDay(`${currentChatUser.follow_up}T00:00:00`,"PT") }} 
            {{formatDate(`${currentChatUser.follow_up}T00:00:00`,'/', false)}}
            <div class="removeBtn" @click='followUpDialog = true'>
                <v-icon color="red" size="18">mdi-close</v-icon>
            </div>
          </div>
        </v-container>
        <v-divider v-if='currentChatUser.short_text'></v-divider>
        <v-container v-if='currentChatUser.short_text'>
           <p>Anotação</p>
           <div class="message-box shorttext-card">
            {{currentChatUser.short_text}}
            <div class="removeBtn" @click='shortTextDialog = true'>
              <v-icon color="red" size="18">mdi-close</v-icon>
            </div>
          </div>
        </v-container>
        <CloseMessageBox 
           @onConfirm="removeFollowUp(); followUpDialog = false;"
           @onClose="followUpDialog = false"
           :dialog="followUpDialog"
            message="Tem certeza que deseja remover lembrete ?"
            type="error"
        />
        <CloseMessageBox
          @onConfirm="removeShortText(); shortTextDialog = false"
          @onClose="shortTextDialog = false"
          :dialog="shortTextDialog"
          message="Tem certeza que deseja remover anotação ?"
          type="error"
        />
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import api from '../../services/api';

import ChatFollowUp from './ChatFollowUp.vue';
import CustomButtonLoad from '../shared/CustomButtonLoad.vue';
import formatDate from '../../utils/formateDate';
import formatWeekDay from '../../utils/formatWeekDay';
import CloseMessageBox from '../dialogs/CloseMessageBox.vue';

export default {
    name: "ChatConfigContent",
    data(){
        return {
            shortText: '',
            followUpDialog: false,
            shortTextDialog: false,
        }
    },
    props: {
       user: Object
    },
    components: {
        ChatFollowUp,
        CustomButtonLoad,
        CloseMessageBox
    },
    methods: {
        ...mapActions({
            closeRightContent: 'rightcontent/closeRightContent',
            showAlertMessage: "showAlertMessage"
        }),
        formatDate,
        formatWeekDay,
        async saveShortText(){
              if(this.shortText.length > 0 && this.shortText.trim() !== ''){
                  try {
                    await api.post("/shortText", {
                       id: this.currentChatUser.id,
                       short_text: this.shortText
                    })
                } catch (error) {
                     console.log(error);
                     if(error.response){
                       this.showAlertMessage({message: error.response.data.message, type: "info"});
                     }
                }
              }
        },
        async removeShortText(){
              try{
                    await api.post("/shortText", {
                       id: this.currentChatUser.id,
                       short_text:  ''
                    })
                } catch (error) {
                   console.log(error);
                   if(error.response){
                       this.showAlertMessage({message: error.response.data.message, type: "info"});
                     }
                }
        },
        async removeFollowUp(){
             try{
                  await api.post("/followUp", {
                       id: this.currentChatUser.id,
                    })
                } catch (error) {
                    console.log(error);
                   if(error.response){
                       this.showAlertMessage({message: error.response.data.message, type: "info"});
                     }
                }
        }
    },
    computed: {
        ...mapGetters({
         currentChatUser: 'chat/currentChatUser',
      }),
    }
}
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #1976D2;
}
.message-box{
   word-wrap: break-word;
   position: relative;
   min-width: 200px;
   max-width: 500px;
   min-height: 35px;
   padding: 8px;
   background: #C6E4F8 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 1px #00000029;
   border-radius: 8px;
   opacity: 1;
}
.followup-card {
   font-size: 16px;
   display: flex;
   justify-content: center;
}
.shorttext-card {
    text-align: left;
    font: normal normal normal 14px Roboto;
    position: relative;
    padding-right: 40px;
}

.removeBtn {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

</style>
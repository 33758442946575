<template>
     <v-container>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="#1976D2"
            v-model="date"
            label="Data para entrar em contato"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :min="minDate"
          :max="maxDate"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    <CustomButtonLoad
        v-if="showSaveButton"
        title="Salvar lembrete"
        :customFunction="saveChatFollowUp"
    />
</v-container>
</template>
<script>
import formatDate from '../../utils/formateDate';
import { mapGetters, mapActions } from 'vuex';
import api from '../../services/api';

import CustomButtonLoad from '../shared/CustomButtonLoad.vue';

export default {
    name: "ChatFollowUp",
    data(){
        return {
            date: this.minDate,
            menu: false,
            showSaveButton: false
        }
    },
    components: {
      CustomButtonLoad
    },
    methods: {
        ...mapActions({
          showAlertMessage: "showAlertMessage"
        }),
        async saveChatFollowUp(){     
            if(this.date){
             try {
                 await api.post('/followUp', {
                    id: this.currentChatUser.id,
                    follow_up: this.date
                });
             } catch (error) {
                 console.log(error);
                 console.log(error);
                   if(error.response){
                       this.showAlertMessage({message: error.response.data.message, type: "info"});
                  }
             }
          }
        }
    },
    computed: {
        ...mapGetters({
            currentChatUser: 'chat/currentChatUser'
        }),
        minDate(){
            const today = new Date();

            //console.log(formatDate(today,"-", true));

            return formatDate(today,"-", true);
        },
        maxDate(){
            const todayOneYearLater = new Date();

            todayOneYearLater.setFullYear(todayOneYearLater.getFullYear() + 1);

            //console.log(formatDate(todayOneYearLater,"-", true));

            return formatDate(todayOneYearLater,"-", true);
        }
    },
    watch: {
        date(val){
            if(val){
                this.showSaveButton = true; 
            }
        },
    }
}

</script>
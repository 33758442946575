<template>
    <v-col
          md='auto'
          :class="['d-flex', alignClass]"
        >
        <v-dialog
          v-model="dialog"
        >
        <div class="video-box">
        <v-btn
          class="close-button-video"
          elevation="2"
          large
          icon
          @click="dialog = false"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
            <video class="video-dialog" controls autoplay>
              <source :src="video.url" type="video/mp4">
              Your browser does not support the video tag.
            </video>
        </div>
    </v-dialog>
          <v-card
            :color="messageColor(data.message.Body.Info.FromMe)"
            dark
            class="pa-0 pb-5"
          >
        <v-btn
          elevation="2"
          large
          class="play-button"
          icon
          @click="dialog = true"
         >
        <v-icon>mdi-play</v-icon>
        </v-btn>
            <v-container>
               <video  class="video-size" >
                    <source :src="video.url" type="video/mp4">
                    Your browser does not support the video tag.
                 </video>
            </v-container>
            <MessageCardInfo :data="data" />
          </v-card>
        </v-col>
</template>
<script>
import MessageCardInfo from './MessageCardInfo.vue';
export default {
    name: "videoCard",
    props: {
        data: Object,
    },
    components: {
      MessageCardInfo
    },
    data(){
        return {
             video: {
              width: this.data.message.Body.Info.Source.message.videoMessage.width,
              height: this.data.message.Body.Info.Source.message.videoMessage.height,
              thumbnail: this.data.message.Body.Info.Source.message.videoMessage.jpegThumbnail,
              url: this.data.message.Url
             },
            dialog: false
        }
    },
    methods: {
     messageColor(value){
           return value ? '#C6E4F8' : '#FFFFFF'
       },
    },
    computed: {
        alignClass() {
            return this.data.message.Body.Info.FromMe ? 'justify-end' : 'justify-start';
        }
    }
}
</script>
<style scoped>
.play-button{
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  z-index: 100;
  transform: translate(-50%,-50%);
}
.video-box {
    position: relative;
    text-align: center;
}
.close-button-video {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
}

.video-size {
    object-fit: cover;
    max-height:  400px;
    max-width: 250px;
}
.video-dialog {
    object-fit: cover;
    max-height: 80vh;
    max-width: 80vw;
}
</style>
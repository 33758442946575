import formatDate from "./formateDate";

export default function arrayDatesToObj(dates){
    let searchDates = {};
             if(dates.length < 2){
                 dates.push(dates[0]);
                 searchDates = {
                    "start_date": dates[0],
                    "end_date": dates[1],
                 };
             }else {
                 const firstDate = new Date(`${dates[0]}T00:00:00`);
                 const secondDate = new Date(`${dates[1]}T00:00:00`);
                 const startDate = firstDate > secondDate ? secondDate : firstDate;
                 const endDate = firstDate > secondDate ? firstDate : secondDate;
                 searchDates = {
                    "start_date": formatDate(startDate,"-", true),
                    "end_date": formatDate(endDate,"-", true),
                };
            }   
    return searchDates;
}
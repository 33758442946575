<template>
    <v-col
        xs='auto'
        sm='auto'
        md='auto'
        :class="['d-flex', alignClass]"
    >
        <v-card
                v-if='data.isDateMessage'
                class="rounded-lg" 
                elevation="1"
                dark
                color="#C6E4F8"
                max-width="140px"
                height="35px"
            >
            <v-card-title class="justify-center pt-2 text-body-2 black--text">
                {{data.message.Body.Text}}
            </v-card-title>
        </v-card>
        <v-card
                v-else-if="data.message.Type === 'chat_begin' || data.message.Type === 'chat_close'"
                class="rounded-lg" 
                elevation="1"
                dark
                color="#C6E4F8"
                max-width="280px"
                min-width="280px"
                height="38px"
            >
            <v-card-title class="justify-center pt-2 text-body-2 black--text">
                {{formatChatBeginEnd(data)}}
            </v-card-title>
        </v-card>
        <div
          v-else-if='data.message.Body.Info.FromMe'
           class="color-from-me message-box"
         >
            <p class="text-message-title" ><span class="font-weight-bold">Por:</span> {{data.user.user_name}}</p>
            <p class='text-message'  v-html="formatMessage(data.message.Body.Text)"></p>
            <div class="bottom-info-blue">
                <MessageCardInfo  :data='data' />
            </div>
        </div>
        <div
           v-else
           class="message-box"
         >
             <p class='text-message'  v-html="formatMessage(data.message.Body.Text)"></p>
            <div class="bottom-info">
                <MessageCardInfo  :data='data' />
            </div>
        </div>
</v-col>
    
</template>
<script>
import MessageCardInfo from './MessageCardInfo.vue';
import formatDate from '../../utils/formateDate'
import formatHours from '../../utils/formatHours';
export default {
    name: "TextMessage",
    props: {
         data: Object
    },
    components: {
        MessageCardInfo
    },
    methods: {
         formatChatBeginEnd(data){
            return data.message.Type === 'chat_begin' ? `Chat Iniciado em ${this.formateDateAndHours(data.date)}` : 
               `Chat Encerrado em ${this.formateDateAndHours(data.date)}`;
         },
          formatTitle(message){
           if(message){
               const value = message.replace(/\*/g,'"');
               const value2 =  value.match(/"(.*?[^\\])"/);
               if(value2){
                const index = value2[1].indexOf('-');

                return `${value2[1].slice(0,index - 1)}`;
              }
             return value2;
          }
          return message;
       },
    formatMessage(message){
   const value = message.replace(/"/g,"%%").replace(/\*/g,'"');

        let value2 = value.match(/"(.*?[^\\])"/);

        if(value2 !== null){
            let result = value.replace(/\n/g,'<br>');

            value2 = result.match(/"(.*?[^\\])"/);

            while(value2 !== null){

                result = value2[1].includes("<br>") ? 
                result.replace(/".*?"/,`*${value2[1]}*`) :
                result.replace(/".*?"/,`<b>${value2[1]}</b>`);
            
                value2 = result.match(/"(.*?[^\\])"/);
            }
            return result.replace(/%%/g,'"');
        }
        return value.replace(/\n/g,'<br>').replace(/%%/g,'"');
    },
    messageColor(value){
           return value ? '#C6E4F8' : '#FFFFFF'
       },
       formateDateAndHours(dateString){
           return `${formatDate(dateString,'/',false)} ${formatHours(dateString)}`
       }
    },
    computed: {
        alignClass() {
            if(this.data.isDateMessage){
                return 'justify-center';
            }else if(this.data.message.Type === 'chat_begin' || this.data.message.Type === 'chat_close'){
                return 'justify-center';
            }else if (this.data.message.Body.Info.FromMe){
                return 'justify-end';
            }else {
                return 'justify-start';
            }
        }
    }
}
</script>
<style scoped>

.message-box{
   word-wrap: break-word;
   position: relative;
   min-width: 200px;
   max-width: 500px;
   min-height: 60px;
   padding: 8px;
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 1px #00000029;
   border-radius: 8px;
   opacity: 1;
}

.color-from-me {
    background: #C6E4F8;
}
.text-message {
    text-align: left;
    font: normal normal normal 16px Roboto;
    letter-spacing: 0px;
    color: #58606B;
    opacity: 1;
}
.text-message-title {
    text-align: left;
    font: italic normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #7B8491;
    margin-bottom: 8px;
}
.bottom-info {
    position: absolute;
    right: 4px;
    bottom: 6px;
}
.bottom-info-blue {
    position: absolute;
    right: 2px;
    bottom: 10px;
}
</style>
<template>
   <div>
        <CloseMessageBox 
            message="Tem certeza que deseja desconectar ?"
            :dialog="dialogDisconnect" 
            @onClose="dialogDisconnect = false" 
            @onConfirm="disconnectPhone(); dialogDisconnect = false"
            type="error"
        />
        <v-btn text @click="dialogDisconnect = true">
                <img :src="disconnectIcon" alt="" width="28">
                <span class="icons-text">Desconectar</span>
        </v-btn>
   </div>
</template>
<script>

import disconnectIcon from '../assets/disconnect.svg';
import api from '../services/api';
import { mapActions } from 'vuex';
import CloseMessageBox from './dialogs/CloseMessageBox.vue';

export default {
     name: "Disconnect",
     data(){
         return {
             dialogDisconnect: false,
             disconnectIcon: disconnectIcon
         }
     },
     components: {
      CloseMessageBox
    },
     methods: {
       ...mapActions({
            showAlertMessage: "showAlertMessage"
        }),
        async disconnectPhone(){
             try {
                await api.get('/disconnect');
                this.showAlertMessage({message: "Telefone Desconectado", type: "info"});
             } catch (error) {
                 if(error.response){
                    this.showAlertMessage({message: error.response.data.message, type: "error"});
                }
             }
        }
     }
}
</script>
<style>
.container-input{
    background-color: #fff;
    width: 450px
}
.icon-text-phone {
  font: normal normal normal 11px/12px Roboto;
  letter-spacing: 0px;
  color: #262A2E;
  opacity: 1;
  text-transform: capitalize;
  margin-top: 5px;
}
</style>
<template>
  <v-app>
    <CustomAlert />
    <QrCodeModal />
     <AppBar />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import AppBar from './components/AppBar';
import CustomAlert from './components/dialogs/CustomAlert.vue';
import QrCodeModal from './components/QrCodeModal.vue';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    AppBar,
    CustomAlert,
    QrCodeModal,
  }
};
</script>
<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #99ccff #f5f5f5; /* thumb and track color */
  scrollbar-width: thin;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  border-radius: 16px
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #99ccff;
  border-radius: 16px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #91c2ff
}

.v-dialog{
  box-shadow: none !important;
}

.v-dialog--active {
  box-shadow: none !important;
}

@media (min-width:960px) and (max-width:1540px) {
  .screen-size-main-container {
     width: 90% !important;
   }
}

@media (min-width:1541px){
  .screen-size-main-container {
     width: 80% !important;
   }
}
</style>

<template>
    <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
<script>
import { Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: { Pie },
  props: {
    asyncChartData: Array,
    loading: Boolean,
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
       labels: [
         'Red',
         'Blue',
         'Yellow'
       ],
    datasets: [{
    label: 'My First Dataset',
    data: [300, 50, 100],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
  }]
      },
      chartOptions: {
        responsive: true
      }
    }
  },
  watch: {
      asyncChartData(asyncData){
        // console.log("Update Chart Data", asyncData);
        const data = [];
        const labels = [];
        asyncData.map(item => {
            data.push(item.value)
            labels.push(item.name)
        })
        this.chartData = {labels,datasets: [{...this.chartData.datasets[0],data}]};

        // console.log("updated ChartData", this.chartData);
      }
  }
}
  
</script>

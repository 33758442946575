<template>
    <v-row
          align="center"
          justify="end"
          class='pr-4'
        >
        <v-progress-circular
            v-if="resendLoading && data.messageWithError"
            indeterminate
            color="primary"
            size="18"
            class='mr-8'
       ></v-progress-circular>
        <v-btn 
            text 
            v-if='data.messageWithError && !resendLoading'
            @click='sendMessage(data.message.Body.Text)'
            class='mr-2'
        >
             <v-icon color="blue" size="18">
                 mdi-refresh 
             </v-icon>
        </v-btn>
             <p class="text-date mr-1 mb-n1">{{formateDateAndHours(data.date)}}</p>
        <v-icon  v-if="data.message.Body.Info.FromMe && data.send"  color="blue" size="18">
            mdi-check-all
        </v-icon>
        <v-icon  v-else-if="data.message.Body.Info.FromMe && !data.messageWithError"  color="blue" size="18">
            mdi-check
        </v-icon>
        <v-icon  v-else-if="data.message.Body.Info.FromMe"  color="red" size="18">
            mdi-close
        </v-icon>
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import formatDate from '../../utils/formateDate';
import formatHours from '../../utils/formatHours';

export default {
    name: "MessageCardInfo",
    props: {
        data: Object
    },
    computed: {
        ...mapGetters({
            messageError: 'message/messageError',
            resendLoading: 'message/resendLoading'
        })
    },
    methods: {
        ...mapActions({
            sendMessage: 'message/sendMessage'
        }),
       formateDateAndHours(dateString){
           return `${formatDate(dateString,'/',false)} ${formatHours(dateString)}`
       }
    }
}
</script>
<style>
.text-date {
    text-align: left;
    font: italic normal normal 11px/13px Roboto;
    letter-spacing: 0px;
    color: #7B8491;
    opacity: 1;
}
</style>

<template>
    <v-col
        md="auto"
        :class="['d-flex', alignClass]"
        >
            <v-card
                class="rounded-lg pl-2 pt-2 pb-4"
                elevation="1"
                dark
                :color="messageColor(data.message.Body.Info.FromMe)"
                min-width='200'
                max-width="600"
            >
            <div class="d-flex justify-center align-center mb-2 mr-2">
            <v-img :src="fileImage" max-width="40" ></v-img>
                <a :href="fileSource" type="application/pdf" target="_blank">
                   <p class="file-link"> {{ formatFileName(fileName)}}</p>
                </a>
            </div>
             <MessageCardInfo :data='data' />
        </v-card>
</v-col>
</template>
<script>
import MessageCardInfo from './MessageCardInfo.vue';
import pdfImage from '../../assets/pdf.png';
import docImage from '../../assets/doc.png';
import txtImage from '../../assets/txt.png';
import csvImage from '../../assets/csv-file.png';
import xlsImage from '../../assets/xls.png';
import xlsxImage from '../../assets/xlsx.png';

import formatExtension from '../../utils/formatExtension';

export default {
    name: "FileMessage",
    props: {
         data: Object
    },
    components: {
        MessageCardInfo
    },
    data(){
        return {
            fileName: this.data.message.Title ? this.data.message.Title : this.data.message.Body.FileName,
            fileSource: this.data.message.Url  ? this.data.message.Url : this.data.message.Body.Url,
        }
    },
    methods: {
       formatExtension,
       messageColor(value){
           return value ? '#C6E4F8' : '#FFFFFF'
       },
       formatFileName(value){
          const idx = value.indexOf(';');
          if(idx !== -1){
              const str = value.substring(0,idx);
              return str;
          }
          return value;
       },
    },
    computed: {
        alignClass() {
            return this.data.message.Body.Info.FromMe ? 'justify-end' : 'justify-start';
        },
        fileImage() {
            switch(this.formatExtension(this.data.message.Body.FileName)){
                      case "pdf" :
                          return pdfImage
                      case "doc":
                          return docImage
                      case "docx":
                           return docImage
                      case "xls":
                          return xlsImage
                      case "xlsx":
                          return xlsxImage
                      case "txt":
                          return txtImage;
                      case "csv":
                          return csvImage; 
                      default: 
                          return ''
                  }
        }
    }
}
</script>
<style scoped>
a {
    text-decoration: none;
}
.file-link {
    margin: 0;
    text-align: left;
    font: normal normal bold 16px/19px Roboto;
    color: #303439;
}
</style>
<template>
    <v-col
        md="6"
        >
            <v-card
                class="pl-6 rounded-lg"
                elevation="1"
                dark
                :color="messageColor(data.message.Body.Info.FromMe)"
            >
            <v-card-title class="pt-2 black--text">
                Arquivo Desconhecido
            </v-card-title>
        </v-card>
</v-col>
</template>
<script>
export default {
    name: "ApiMessage",
    props: {
         data: Object
    },
    methods: {
        messageColor(value){
           return value ? '#BBDEFB' : '#E3F2FD'
       }
    }
}
</script>
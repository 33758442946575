<template>
       <v-list-item
          @click="switchChat({ profile: item.profile, 
                               user: item.user, 
                               id: item.id, 
                               status: item.status, 
                               short_text: item.short_text, 
                               follow_up: item.follow_up
                             }); 
                  $emit('closeLeftContent')"
          :class='[chatColor(item.id),followUpColor(item),closedChat(item.status),clienteLastMessage(item), "justify-center"]'
        >
         <v-list-item-avatar
          size="56"
          class="overflow-visible"
         >
           <v-img :src="item.profile.url ? item.profile.url : default_user"></v-img>
           <div class="badge-typing d-none d-sm-flex d-md-none" v-if='isTypingMessage[item.profile.number] && item.status !== "E"'>
              <v-icon color='#fff'>mdi-dots-horizontal </v-icon>
           </div>
         </v-list-item-avatar>
        <v-list-item-content class="d-sm-none d-md-flex" >
          <v-list-item-title class="mb-0" v-html="item.profile.name ? item.profile.name : formatPhoneNumber(item.profile.number)"></v-list-item-title>
              <v-list-item-subtitle class="font-italic text-caption" v-if='isTypingMessage[item.profile.number] && item.status !== "E"'>
                 Digitando...
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-caption mb-0 mt-n1" v-else>
                {{ item.status_name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.user" class="mt-n2">
                   <b>Por:</b> <span class="font-italic text-caption">{{formatUserName(item.user.user_name)}}</span>
              </v-list-item-subtitle>
        </v-list-item-content>
        <div>
            <v-card v-if='dateFollowUp(item)' class="pa-1 d-sm-none d-md-flex" height="28px" elevation="0">
                  <p class="text-body-2">{{dateFollowUp(item)}}</p>
            </v-card>
            <div class="badge"
              v-if="newMessages[item.id] && newMessages[item.id].length !== 0"
            >
            {{newMessages[item.id].length}}
            </div>
            <audio controls class="d-none" id="notification-player">
                <source :src="audioNotification" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
            <span class="font-italic text-caption mt-10">{{lastMessageHour(item)}}</span>
        </div>
        </v-list-item>  
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import default_user from '../assets/default_user.jpg';
import checkFollowUpExpired from '../utils/checkFollowUpExpired';
import formatDate from '../utils/formateDate';
import formatHours from '../utils/formatHours';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import notificationSound from '../assets/notification-sound.mp3';

export default {
    name: "ChatItem",
    props: {
       item: Object
    },
    data() {
        return {
            default_user: default_user,
            audioNotification: notificationSound
         }
    },
    computed: {
        ...mapGetters({
            newMessages: 'message/newMessages',
            newChatData: 'chat/newChatData',
            currentChatUser: 'chat/currentChatUser',
            isTypingMessage: 'message/isTypingMessage',
            notificationSound: 'notificationSound'
        }),
    },
    methods: {
        ...mapActions({
           loadMessages: 'message/loadMessages',
           loadClosedChatMessages: 'message/loadClosedChatMessages'
       }),
        formatUserName(username){
         return username.length > 20 ? `${username.substring(0,20)}...` : username;
       },
       formatPhoneNumber: formatPhoneNumber,
        switchChat(data) {
           if(data.status == "E"){
             this.loadClosedChatMessages(data);
           }else {
              this.loadMessages(data);
           }
       },
       chatColor(item_id){
          return this.newChatData[item_id]
                 && this.newChatData[item_id].user.id === this.user.id
                 && this.newChatData[item_id].id !== this.currentChatUser.id
                 ? 'transferColor' : "";
        },
        followUpColor(item){
            return checkFollowUpExpired(item) ? "followUpColor" : "" 
        },
        clienteLastMessage(item){
          return item?.last_message_from_client ? "last-message-color" : ""
        },
        closedChat(status){
              return status === "E" ? 'closedChat' : "";
        },
        dateFollowUp(item){
              return checkFollowUpExpired(item) ? formatDate(`${item.follow_up}T00:00:00`, '/', false) : false;
        },
        lastMessageHour(item){
          return item.last_message && item.last_message.date ? formatHours(item.last_message.date): "";
        },
    },
    watch: {
        newMessages(val){
        if(val[this.item.id] && val[this.item.id].length > 0 && this.notificationSound){
        const notificationPlayer = document.getElementById('notification-player');
        notificationPlayer.play();
       }
      }
    }
}
</script>
<style>
.transferColor {
  background-color: #42A5F5;
}
.followUpColor {
  background-color: #FFF4AF;
}

.last-message-color {
  background-color: #C6F8CE;
  border-left: 5px solid #30b646;
}
.closedChat {
  opacity: 0.5;
}

.badge {
   width: 15px;
   height: 15px;
   font-size: 12px;
   background: #19D269;
   color: #fff;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 2px;
}
.badge-typing {
  width: 23px;
  height: 12px;
  background: #19D269;
  border: 2px solid #FFF;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
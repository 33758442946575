<template>
    <v-list three-line>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
      <template v-for="(item, index) in chatData">
        <v-subheader
          class="grey--text text--darken-4 font-weight-bold d-sm-none d-md-flex"
          v-if="item.header"
          :key="item.header"
          v-text="item.header"
        ></v-subheader>

        <v-divider
          v-else-if="item.divider"
          :key="index"
          :inset="item.inset"
        ></v-divider>
        <template>
          <ChatItem :item="item" :key="item.id" />
        </template>
      </template>
      </v-list-item-group>
    </v-list>
</template>
<script>
import { mapGetters } from 'vuex';
import ChatItem from './ChatItem.vue';

export default {
    name: "Chats",
    props: {
       chatsData: Array
    },
    data() {
        return {
            chats: this.chatsData,
            selectedItem: 0,
         }
    },
    components: {
      ChatItem
    },
    methods: {
        sortChats(){
          this.chats.sort((a,b) => {
              if(a.status === "I" && (b.status === "A" || b.status == "E") ){
                return -1;
              }else if(a.status === "A" && b.status == "E"){
                return 1;
              }else {
                return 0;
              }
          })
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
        chatData(){
            let data = [];
            const myChats = this.chats.filter(item => item.user && item.user.id === this.user.id && item.status !== 'E');

            myChats.sort((a, b ) => {
                const now = new Date();
                now.setHours(now.getHours() -1);
                const dateA = a?.last_message?.date ? new Date(a.last_message.date) : now;
                const dateB = b?.last_message?.date ? new Date(b.last_message.date) : now;
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
            });
            
            if(myChats.length !== 0){
               data.push({ header: 'Meus Atendimentos' });
               myChats.map(item => data.push(item));
               data.push({ divider: true, inset: false });
            }
            const waiting = this.chats.filter(item => item.status === "I");

            waiting.sort((a, b ) => {
                const now = new Date();
                now.setHours(now.getHours() -1);
                const dateA = a?.last_message?.date ? new Date(a.last_message.date) : now;
                const dateB = b?.last_message?.date ? new Date(b.last_message.date) : now;
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
            });

            if(waiting.length !== 0){
                data.push({ header: 'Aguardando Atendimento' });
                waiting.map(item => data.push(item));
                data.push({ divider: true, inset: false });
            }

            const allChats =  this.chats.filter(item => item.status === "A" && item.user.id !== this.user.id);

            if(allChats.length !== 0){
               data.push({ header: 'Chats Em Atendimento' });
               allChats.map(item => data.push(item));
               data.push({ divider: true, inset: false });
            }

            const closedChats = this.chats.filter(item => item.status === "E");

            if(closedChats.length !== 0){
               data.push({ header: 'Chats Encerrados' });
               closedChats.map(item => data.push(item));
            }
            
            return data;
        },
    },
    watch: {
     chatsData(val){
          this.chats = val;
      },
    }
}
</script>